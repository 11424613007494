import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrManager } from 'ng6-toastr-notifications';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { StaffingAgencyServices } from 'src/app/admin/service/staffing-agency.service';
import * as moment from 'moment';
import { ShareService } from '../share.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-staffing-invitation-link',
  templateUrl: './staffing-invitation-link.component.html',
  styleUrls: ['./staffing-invitation-link.component.css']
})
export class StaffingInvitationLinkComponent implements OnInit {
  skils: any;
  [x: string]: any;
  baseUrl = '';
  id = '';
  Invitationtbl: any;
  invitationForm: FormGroup;
  candidateList: FormGroup;
  mergetableData: any;
  Interested: boolean;
  Notclick: boolean = true;
  jobseekerStatus: boolean;
  submitted: boolean;
  ComapanyName: string;
  title: string;
  city: string;
  state: string;
  postalCode: string;
  JobseekrName: string;
  email: string;
  phone: string;
  location: string;
  updateInvitationResult: Object;
  beelingdataForVms: any;
  UpdateStatus: any;
  Srate: any;
  annualSalary: any;
  beelineRequestId: any;
  jobType: any;
  jobseekerId: any;
  pay_rate: any;
  request_id: any;
  staffingAgencyId: any;
  staffingAgencyStatus: boolean;
  userData:any={}
  jobid ='';
  typeUser:number;
  constructor(private _activated: ActivatedRoute, private layoutService:LayoutService, private stafingagencyService: StaffingAgencyServices, private _titleService: Title,private shareservice:ShareService,
    private _formBuilder: FormBuilder, private router: Router) {
    const newTitle = 'Virtusa | SA Verification';
    this._titleService.setTitle(newTitle);
    this.id = this._activated.snapshot.params['id'];

    this.baseUrl = "/";
    this.typeUser = this._activated.snapshot.params['type'];
    this.userData =  JSON.parse(window.localStorage.getItem('udata'));
    this.invitationForm = this._formBuilder.group({
      rate: ['', Validators.required],
      SAStatus: false,
      verifycode: '',
    });
    this.verifyinvitation(this.id);

    // this.verifyinvitation('ff342433sc24344s344fs23f3cc444fcf4344334');

  }

  get pl() { return this.invitationForm.controls; }

  verifyinvitation(code: any) {
    this.stafingagencyService.invitationVerifiy(code).subscribe(res => {
      this.Invitationtbl = res;
      this.jobid=this.Invitationtbl.data[0].jobId.split('-');
      //console.log('test',this.jobid[0])
      if (this.Invitationtbl.success) {

        this.Invitationtbl.data.forEach(element => {
          if (element.staffingAgencyStatus === null) {
            //console.log('element', element, element.staffingAgencyStatus);

            this.Notclick = true;
            if (element.VerifiyCode.trim() === code) {
              this.stafingagencyService.JobAndSeekerDetails(code).subscribe(res => {
                this.mergetableData = res;
                if (this.mergetableData.success) {
                  if(this.typeUser == 1){
                    if(this.jobid == 'Job'){
                      this.ComapanyName = this.mergetableData.data[1].StaffingAgencyName;
                      this.title = this.mergetableData.data[0].title;
                      this.city = this.mergetableData.data[0].city;
                      this.state = this.mergetableData.data[0].state;
                      this.postalCode = this.mergetableData.data[0].postalCode;
                      this.JobseekrName = this.mergetableData.data[0].JobseekrName;
                      this.email = this.mergetableData.data[0].email;
                      this.phone = this.mergetableData.data[0].phone;
                      this.skils = this.mergetableData.data[0].skils;
                      this.location = (this.city == null ? '' : this.city) + '-' + (this.postalCode == null ? '' : this.postalCode)
                        + '-' + (this.state == null ? '' : this.state);
                    }
                    else{
                      this.ComapanyName = this.mergetableData.data[0].ComapanyName;
                      this.title = this.mergetableData.data[0].title;
                      this.city = this.mergetableData.data[0].city;
                      this.state = this.mergetableData.data[0].state;
                      this.postalCode = this.mergetableData.data[0].postalCode;
                      this.JobseekrName = this.mergetableData.data[1].JobseekrName;
                      this.email = this.mergetableData.data[1].email;
                      this.phone = this.mergetableData.data[1].phone;
                      this.skils = this.mergetableData.data[1].skils;
                      this.location = (this.city == null ? '' : this.city) + '-' + (this.postalCode == null ? '' : this.postalCode)
                        + '-' + (this.state == null ? '' : this.state);
                    }

                  }
                  if(this.typeUser == 2){
                    if(this.jobid == 'Job'){
                      this.ComapanyName = this.mergetableData.data[1].StaffingAgencyName;
                      this.title = this.mergetableData.data[0].title;
                      this.city = this.mergetableData.data[0].city;
                      this.state = this.mergetableData.data[0].state;
                      this.postalCode = this.mergetableData.data[0].postalCode;
                      this.JobseekrName = this.mergetableData.data[0].JobseekrName;
                      this.email = this.mergetableData.data[0].email;
                      this.phone = this.mergetableData.data[0].phone;
                      this.skils = this.mergetableData.data[0].skils;
                      this.location = (this.city == null ? '' : this.city) + '-' + (this.postalCode == null ? '' : this.postalCode)
                        + '-' + (this.state == null ? '' : this.state);
                    }
                    else{
                      this.ComapanyName = this.mergetableData.data[0].ComapanyName;
                      this.title = this.mergetableData.data[0].title;
                      this.city = this.mergetableData.data[0].city;
                      this.state = this.mergetableData.data[0].state;
                      this.postalCode = this.mergetableData.data[0].postalCode;
                      this.JobseekrName = this.mergetableData.data[1].JobseekrName;
                      this.email = this.mergetableData.data[1].email;
                      this.phone = this.mergetableData.data[1].phone;
                      this.skils = this.mergetableData.data[1].skils;
                      this.location = (this.city == null ? '' : this.city) + '-' + (this.postalCode == null ? '' : this.postalCode)
                        + '-' + (this.state == null ? '' : this.state);
                    }

                  }

                  this.invitationForm.controls['verifycode'].setValue(code);
                  this.invitationForm.controls['SAStatus'].setValue(true);
                }
              });
              this.staffingAgencyStatus = true;
              this.Interested = true;
            } else {
              if (element.RejectedCode.trim() === code) {
                this.staffingAgencyStatus = false;
                this.Interested = false;
                this.stafingagencyService.updateinvitation_requestSA(code).subscribe(res => {
                  this.updateInvitationResult = res;
                  if (this.Invitationtbl.success) {
                    //alert('success Update false');
                  }
                });
              }
            }
          } else {
            this.Notclick = false;
          }

        });
      }
    });
  }
  AddStaffingdetails() {
    this.submitted = true;
    if (this.invitationForm.valid) {
      const rate = this.invitationForm.controls.rate.value;
      //console.log('this.invitationForm', this.invitationForm.value);
      this.stafingagencyService.UpdateStafingStatus(this.id, rate).pipe(first()).subscribe(x => {

        this.UpdateStatus = x;
        //console.log('this.UpdateStatus', this.UpdateStatus.data);
        if (this.UpdateStatus.success) {
          this.stafingagencyService.GetbeelingdataForVms(this.id,this.typeUser).pipe(first()).subscribe(res => {

            this.beelingdataForVms = res;
            if (this.beelingdataForVms.success) {
              //console.log('this.beelingdataForVms.data', this.beelingdataForVms.data[0]);
              this.Srate = this.beelingdataForVms.data[0].rate;
              this.annualSalary = this.beelingdataForVms.data[0].annualSalary;
              this.beelineRequestId = this.beelingdataForVms.data[0].beelineRequestId;
              this.jobType = this.beelingdataForVms.data[0].jobType;
              this.jobseekerId = this.beelingdataForVms.data[0].jobseekerId;
              this.pay_rate = this.beelingdataForVms.data[0].pay_rate;
              this.request_id = this.jobid == 'Job'?this.Invitationtbl.data[0].jobId:this.beelingdataForVms.data[0].request_id;
              this.staffingAgencyId = this.beelingdataForVms.data[0].staffingAgencyId;
              this.candidateList = this._formBuilder.group({
                applyType: 'Submit',
                beelineRequestId: this.beelineRequestId,
                request_id: this.request_id,
                assignStatus: 1,
                updatedBy: 'Pool',
                jobType: this.jobType,
                payRate: rate,//this.pay_rate == null ? this.annualSalary : this.pay_rate,
                staffingagencyId: this.staffingAgencyId,
                vkStatus: null,
                interviewStatus: null,
                notes: '',
                jobseekerId: this.jobseekerId,
                clientstatus: null,
                vk_pay_rate: 0.0,
                vmsaction: null,
                accept_offer_url: null,
                reject_offer_url: null,
                candidate_status: null,
                assignment: null,
                notes_for_staffing_agency: null,
                change_request_json: 0,
                vk_payRate: null,
                statusDate:'',
                createdby:JSON.parse((localStorage.getItem('CreatedBy')))
              });
              //console.log('this.UpdateStatus', this.candidateList.value);
              this.candidateList.controls['statusDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()))
              this.stafingagencyService.assignJobseekerBySAVerify(this.candidateList.value).pipe(first()).subscribe(res => {
                this.beelingdataForVms = res;
                if (this.beelingdataForVms.success) {
                  // location.href = environment.verisourceUrl//"https://verisource.veriklick.com";
                  this.Interested = false;
                  this.submitted = false;
                }
              });

            }
          });
        }
      });
      // ............ Here we need to update write two service in side one service ..............
      // ............ First for update Status of stafing Agency status data and second for beeling VMS ..................
      // this.stafingagencyService.UpdateSeekerAndStaffingDetails(this.invitationForm.value).pipe(first()).subscribe(res => {
      // this.updateInvitationResult = res
      // if (this.updateInvitationResult.success) {
      // location.href = "https://www.veriklick.com/";
      // this.submitted = false;
      // }
      // });
    }

  }

  ngOnInit() {
    this.layoutService.setShowSidebar(false);
  }

}
