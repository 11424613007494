import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { EnterpriseService } from '../enterprise.service';
import { first } from 'rxjs/operators';
import { AddAgencyInGroupComponent } from '../add-agency-in-group/add-agency-in-group.component';
import { AddAgencyGroupComponent } from '../add-agency-group/add-agency-group.component';
import { ExcelService } from 'src/app/admin/service/excel.service';
import {AuthenticationService } from '../../app.service';
import { DatePipe } from '@angular/common';
import { ShareService } from 'src/app/share-module/share.service';
import { SupplierGroupDetailsComponent } from '../supplier-group-details/supplier-group-details.component';
import moment from 'moment';


@Component({
  selector: 'app-staffing-agency-group',
  templateUrl: './staffing-agency-group.component.html',
  styleUrls: ['./staffing-agency-group.component.css']
})
export class StaffingAgencyGroupComponent implements OnInit {

  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  groupForm: FormGroup;
  grouplist: any;
  csv_grouplist:any=[];
  preloader: boolean;
  showDetails: boolean;
  blocksec: any;
  unblocksec: any;
  isAddform: boolean = true;

  paginator = {
    pageSize: 10,
    length: 0,
    pageSizeOptions: [10, 15, 20],
    pageIndex: 0
  };
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];
  constructor(public dialog: MatDialog, private _titleService: Title, private formBuilder: FormBuilder,
    private tostr: ToastrManager,private datePipe:DatePipe, private _router: Router, private enterPriseAPI: EnterpriseService,
 private AuthenticationService: AuthenticationService,
 private excelService: ExcelService, private shareservice :ShareService) {

    this.AuthenticationService.jwtTokenExpCheck();

    const newTitle = 'Virtusa | Supplier Group ';
    this._titleService.setTitle(newTitle);
    this.groupForm = this.formBuilder.group({
      search: '',
      PageSize: 10,
      PageNumber: 1,
      fromdate:'',
      todate:'',
      status:'All'
    });
    this.loadgrouplist();
  }

  ngOnInit() {
    //this.loadgrouplist();
  }

  searchButton = false;
  loadData(){
    this.searchButton= true;
    this.loadgrouplist();
  }

  clearPoolData(){
    this.today = new Date().toISOString().split('T')[0];
    this.from_Date = null;
    this.groupForm = this.formBuilder.group({
      search: '',
      PageSize: 10,
      PageNumber: 1,
      fromdate: '',
      todate: '',
      status:'All'
    });
    this.paginator.pageSize=10;
    this.loadgrouplist();
    this.paginators.pageIndex = 0;
  }

  loadgrouplist() {

    this.AuthenticationService.jwtTokenExpCheck();
    //console.log('sun loadgrouplist',this.groupForm.value);

    // if(this.groupForm.controls["fromdate"].value==null &&   this.groupForm.controls["todate"].value==null  ){
    //   this.groupForm.controls["fromdate"].setValue('');
    //   this.groupForm.controls["todate"].setValue('');
    // }
    // this.groupForm.controls["fromdate"].setValue(this.datePipe.transform(this.groupForm.controls["fromdate"].value, "yyyy-MM-dd"))
    // this.groupForm.controls["todate"].setValue(this.datePipe.transform(this.groupForm.controls["todate"].value, "yyyy-MM-dd 23:59:59"))

    if(this.searchButton == true){
      this.groupForm.controls['PageNumber'].setValue(1);
    }
    // this.groupForm.controls["fromdate"].setValue(this.shareservice.convertDateIntoUtc(this.datePipe.transform(this.groupForm.value.fromdate, "yyyy-MM-dd")));
    // this.groupForm.controls["todate"].setValue(this.shareservice.convertDateIntoUtcForSearch(this.datePipe.transform(this.groupForm.value.todate, "yyyy-MM-dd")));
    // this.groupForm.controls["fromdate"].setValue(this.shareservice.convertDateIntoUtc( this.groupForm.controls["fromdate"].value))
    // this.groupForm.controls["todate"].setValue(this.shareservice.convertDateIntoUtc( this.groupForm.controls["todate"].value))

    this.groupForm.value.fromdate=(this.shareservice.convertDateIntoUtc(this.datePipe.transform(this.groupForm.value.fromdate,'MM/dd/yyyy')))
    this.groupForm.value.todate=(this.shareservice.convertDateIntoUtcForSearch(this.datePipe.transform(this.groupForm.value.todate,'MM/dd/yyyy')))

    this.groupForm.controls['fromdate'].setErrors(null);
    this.groupForm.controls['todate'].setErrors(null);

    this.preloader = true;
    this.enterPriseAPI.SAgroupDetails(this.groupForm.value).pipe(first()).subscribe(res => {

      if (res.success) {
        this.preloader = false;
        //console.log('listOfAllRequest',res.data);
        //console.log(res.data);
        this.grouplist = res.data;
        this.isBlock=false;
        this.isUnBlock = false;
        if(this.searchButton == true)
        {
          this.paginators.pageIndex = 0;
        }
        this.searchButton = false;
        this.showDetails = true;
        if (this.grouplist.length > 0) {
          this.paginator.length = this.grouplist[0].totalCount;
        }

        this.grouplist.forEach(element => {
          this.csv_grouplist.push({
              'Group ID': element.staffingAgencyGroup_Id,
              'Group Name': element.GroupName,
              'Total Suppliers': element.totalAgency,
              'Created Date': this.datePipe.transform(element.createdDate,'MMM-dd-yyyy','locale'),
              'Description':element.description,
              'Status': element.status,

            });
      });
      }
    });
  }

  isLogin='true';
  openDialog() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(AddAgencyGroupComponent, {
      width: 'auto',
      data: this.isAddform = true
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.loadgrouplist();
    });
  }
  }


  //

  supplierList(record: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){

    localStorage.removeItem('CurrentGroupRequest');
    localStorage.setItem('CurrentGroupRequest', JSON.stringify(record));
    record = {
      update: true
    }
    const dialogRef = this.dialog.open(SupplierGroupDetailsComponent, {
      width: 'auto',
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {

     // //console.log('The dialog was closed');
      //this.loadgrouplist();
    });
  }
  }

  supplierHistoryList(record: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){

    localStorage.removeItem('CurrentGroupRequest');
    localStorage.setItem('CurrentGroupRequest', JSON.stringify(record));
    record = {
      update: true,
      isHistory :true
    }
    const dialogRef = this.dialog.open(SupplierGroupDetailsComponent, {
      width: 'auto',
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {

     // //console.log('The dialog was closed');
      //this.loadgrouplist();
    });
  }
  }

  openUpdateSeekers(record: any) {

    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){

    localStorage.removeItem('CurrentGroupRequest');
    localStorage.setItem('CurrentGroupRequest', JSON.stringify(record));
    record = {
      update: true
    }
    const dialogRef = this.dialog.open(AddAgencyInGroupComponent, {
      width: 'auto',
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {

     // //console.log('The dialog was closed');
      this.loadgrouplist();
    });
  }
  }
  openUpdateDialog(record: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(AddAgencyGroupComponent, {
      width: 'auto',
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.loadgrouplist();
    });
  }
  }

  isBlock=false;
  isUnBlock = false;
  blockpool(id) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.enterPriseAPI.groupBlock(id).pipe(first()).subscribe(res => {
      this.blocksec = res;
      if (this.blocksec.success) {
        this.tostr.successToastr(this.blocksec.message, "Success!");
        this.loadgrouplist();
      } else {
        this.tostr.errorToastr(this.blocksec.message, 'OPPS!')
      }
    });
  }

  unblockpool(id) {
    this.AuthenticationService.jwtTokenExpCheck();
    //console.log('unblock', id);
    this.enterPriseAPI.groupUnblock(id).pipe(first()).subscribe(res => {
      this.unblocksec = res;
      if (this.unblocksec.success) {
        this.tostr.successToastr(this.unblocksec.message, "Success!");
        this.loadgrouplist();
      } else {
        this.tostr.errorToastr(this.unblocksec.message, 'OPPS!')
      }

    });
  }
  openAddAgency(record: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    localStorage.removeItem('CurrentGroupRequest');
    localStorage.setItem('CurrentGroupRequest', JSON.stringify(record));
    const dialogRef = this.dialog.open(AddAgencyInGroupComponent, {
      width: 'auto',
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.loadgrouplist();
    });
  }
  }


  exportAsXLSX(): void {
    this.AuthenticationService.jwtTokenExpCheck();
    this.excelService.exportAsExcelFile(this.csv_grouplist, 'sample');
  }

  getpaginatorData(e){
    this.searchButton = false;
    // this.groupForm.controls.PageSize.setValue(e.pageSize);
    // this.groupForm.controls.PageNumber.setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.groupForm.controls['PageSize'].setValue(e.pageSize);
    this.groupForm.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.loadgrouplist();
  }
  from_Date:String;
  dateForToDate(type:number){
    if(type == 1){
      this.from_Date = new Date(this.datePipe.transform(this.groupForm.controls['fromdate'].value)).toISOString();
    }

    if(type == 2){
      this.today = new Date(this.datePipe.transform(this.groupForm.controls['todate'].value)).toISOString();
    }
  }



  getErrorMessage(pickerInput: string): string {
    // if (!pickerInput || pickerInput === '' ) {
    //   return 'Please choose a date.';
    // }
    return this.isMyDateFormat(pickerInput);
  }
  /**
   * An example of custom validator on a date string.
   * Valid date is a string, which is:
   * 1, in the form of YYYY-MM-DD
   * 2, later than today
   * 3, not an invalid value like 2018-20-81
   * @param date a date string
   */
  isMyDateFormat(date: string): string {
    if (date.length !== 10) {
      return 'Invalid input: Please input a string in the form of YYYY-MM-DD';
    } else {
      const da = date.split('-');
      if (da.length !== 3 || da[0].length !== 4 || da[1].length !== 2 || da[2].length !== 2) {
        return 'Invalid input: Please input a string in the form of YYYY-MM-DD';
      } else if (moment(date).isValid()) {
        return 'Invalid date: Please input a date no later than today';
      } else if (!moment(date).isValid()) {
        return 'Invalid date: Please input a date with a valid month and date.';
      }
    }
    return 'Unknown error.';
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
}
