import { Component, OnInit } from '@angular/core';
import { EnterpriseService } from '../enterprise.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';
import {AuthenticationService } from '../../app.service';
import { TableService } from 'src/app/admin/service';
import { JobseekerService } from 'src/app/JobSeeker/jobseeker.service';
import { ConfirmDeleteComponent } from 'src/app/share-module/popUp/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-job-seeker-view-details',
  templateUrl: './job-seeker-view-details.component.html',
  styleUrls: ['./job-seeker-view-details.component.css']
})
export class JobSeekerViewDetailsComponent implements OnInit {
  preloader = true;
  JobID = '';
  showData = false;
  Candidate_Data: any;
  submitted: boolean = false;
  submit: boolean = false;
  isCollapsedMenu = false;
  isverifyBtn = false;
  resumePath = '';
  voicePath = '';
  issuccess: any;
  c_img_Path = '/assets/images/user.svg';
  select_img_path = '/assets/images/user.svg';
  jobseekerBasicDetailsForm: FormGroup;
  jobseekerDetailsForm: FormGroup;
  body: FormGroup;
  countryList: any = [];
  stateList: any = [];
  stateLoader: boolean = false;
  cityLoader: boolean = false;
  cityList: any = [];
  nationalityList: any = [];
  preLoader = false;
  resetForm: FormGroup;
  notMatch: boolean = false;
  isAddform: boolean = false;
  loading: boolean = false;
  ProfileData:any;
  userData: any;
  imgName: any;
  isImgExits: boolean =false;
  resumeName: any;
  isResumeExits: boolean = false;
  response: any;
  resume_path: any;
  resume_name: any;
  constructor(private _router: Router, private formBuilder: FormBuilder, private tostr: ToastrManager,
  private AuthenticationService: AuthenticationService,private dialog:MatDialog,private _paramVal: ActivatedRoute,
  private jsApi:JobseekerService, private jobseekerApi: EnterpriseService, private _titleService: Title,private tableService:TableService) {

    this.AuthenticationService.jwtTokenExpCheck();
    this.select_img_path = localStorage.getItem('profileUpdateImagePath');
    if(this.select_img_path != null){
    this.c_img_Path = this.select_img_path;
  }else {
    this.c_img_Path = localStorage.getItem('profileImagePath');
  }
    //for title set
    var newTitle = 'Virtusa | Job Seeker Details';
    this._titleService.setTitle(newTitle);
    this.JobID = this._paramVal.snapshot.params.id;
    this.resumePath = `${environment.url}/api/admin/image/resume/`;
    this.voicePath = `${environment.url}/api/admin/image/voicesample/`;
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.body = this.formBuilder.group({
      status: 'All',
      pageNumber: 1,
      search: '',
      pageSize: 5
    });

    this.jobseekerApi.country().subscribe((res: any) => {
      if (res.success) {
        this.countryList = res.data;
      } else {
        this.tostr.errorToastr(res.message, 'Error!');
      }

    });
    this.jobseekerApi.nationality().subscribe((res: any) => {
      if (res.success) {
        this.nationalityList = res.data;
      } else {
        this.tostr.errorToastr(res.message, 'Error!');
      }

    });

    this.jobseekerDetailsForm = this.formBuilder.group({
      skill: ['', [Validators.required]],
      experience: ['', [Validators.required]]
    });

    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      re_password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
    });

    this.jobseekerBasicDetailsForm = this.formBuilder.group({
      citizenship: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: ['', [Validators.required]],
      dob: [''],
      emailId: ['', [Validators.required, Validators.email, Validators.max(100)]],
      extn_no: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      middleName: [''],
      password: [''],
      phone_home: [''],
      phone_mobile: ['', [Validators.required]],
      phone_work: [''],
      profileImage: [''],
      ssnNo: ['', [Validators.required, Validators.min(999), Validators.max(9999)]],
      state: [''],
      streetName: [''],
      zipCode: [''],
      skill: [''],
      experience: ['']
    });

  }

  ngOnInit() {
    this.loadCandidateDetails(this.JobID);
    this.profileImage();
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    this.JobseekerResume();
  }
  profileImage() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.preloader = true;
      //let id  = this.userData.data.credentials.AppRefId;
      this.jsApi.profileImageVD(this.JobID).subscribe(res => {

        this.ProfileData = res;
        if(this.ProfileData.data.length > 0){
        var profileImagePath = this.c_img_Path =this.ProfileData.data[0].profileImagePath;
        localStorage.setItem('profileImagePath', profileImagePath);
        this.preloader = false;
      }
      });

  }
  mobMenulist() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
  }
  loadCandidateDetails(id) {
    this.preloader = true;
    this.AuthenticationService.jwtTokenExpCheck();
    this.jobseekerApi.candidateDetails(id).pipe(first()).subscribe(res => {

      this.preloader = false;
      this.showData = true;
      this.Candidate_Data = res;
      this.resumeName = this.Candidate_Data.resume;
      this.ResumeFileExits(this.Candidate_Data.resume);
      this.isverifyBtn = this.Candidate_Data.profile_status === 'Verified' ? true : false;
      // if (this.Candidate_Data.profileImage == null || this.Candidate_Data.profileImage === '') {
      //   this.c_img_Path = '/assets/images/user.svg';
      // } else {
      //   this.c_img_Path = `${environment.url}/api/admin/image/profileimage/${this.Candidate_Data.profileImage}`;
      //   this.imgName = this.Candidate_Data.profileImage
      //   this.ImageFileExits(this.Candidate_Data.profileImage);
      // }
    });
  }

  isSelectedJSid: any = '';
  isExistsEmail: boolean = false;
  isExistsPhone: boolean = false;
  OnChangeExistEmailPhone(field_no) {

    this.isExistsEmail = false;
    this.isExistsPhone = false;

    let obj = '';
    if (field_no == 1) {
      obj = this.jobseekerBasicDetailsForm.controls['emailId'].value;
      if (obj.length > 0) {
        this.AuthenticationService.jwtTokenExpCheck();
        this.jobseekerApi.checkmail(this.Candidate_Data.jsId, obj).subscribe((res: any) => {
          if (res.success) {
            if (res.data.exists) {
              this.submitted = true;
              this.jobseekerBasicDetailsForm.controls['emailId'].setErrors({ 'errors': true });
              this.isExistsEmail = res.data.exists;
            } else {
              this.submitted = false;
              this.isExistsEmail = res.data.exists;
            }
          }
        });
      }
    } else if (field_no == 2) {
      obj = this.jobseekerBasicDetailsForm.controls['phone_mobile'].value;
      if (obj.length > 0) {
        this.jobseekerApi.checkmobile(this.Candidate_Data.jsId, obj).subscribe((res: any) => {
          if (res.success) {
            if (res.data.exists) {
              this.submitted = true;
              this.jobseekerBasicDetailsForm.controls['phone_mobile'].setErrors({ 'errors': true });
              this.isExistsPhone = res.data.exists;
            } else {
              this.submitted = false;
              this.isExistsPhone = res.data.exists;
            }
          }
        });
      }
    }

  }

  verifyJobSeeker(jobseekerId) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.jobseekerApi.verifyenterpriseDetails(jobseekerId, 'JS', 0).pipe(first()).subscribe(res => {
      this.issuccess = res;
      if (this.issuccess.success) {
        this.tostr.successToastr(this.issuccess.message, 'Success!');
        this.issuccess = false;
        this.isverifyBtn = true;
      } else {
        this.tostr.errorToastr(this.issuccess.message, 'OPPS!');
      }
    });

  }


  OnChangePhoneNo(field_no, e) {
    let f_val = '';
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value !== ''  && e.keyCode != 8) {
    if (field_no == 1) {
      this.jobseekerBasicDetailsForm.controls['phone_mobile'].setValue('(' + npa + ')' + ' ' + nxx + '-' + last4);
    }
    else if (field_no == 2) {
      this.jobseekerBasicDetailsForm.controls['phone_work'].setValue('(' + npa + ')' + ' ' + nxx + '-' + last4);
    }
  }
  }

  isEditBasicform = false;
  popUpJobseekerBasicForm() {
    this.jobseekerBasicDetailsForm.controls['skill'].setValue(this.Candidate_Data.skill);
    this.jobseekerBasicDetailsForm.controls['experience'].setValue(this.Candidate_Data.experience);
    this.isEditBasicform = !this.isEditBasicform;
    this.PopupJobseekerForm();
    this.jobseekerBasicDetailsForm.controls['dob'].setValue(this.Candidate_Data.dob);
  }

  isEditform = false;
  PopupJobseekerForm() {
    this.jobseekerBasicDetailsForm.controls['firstName'].setValue(this.Candidate_Data.firstName);
    this.jobseekerBasicDetailsForm.controls['middleName'].setValue(this.Candidate_Data.middleName);
    this.jobseekerBasicDetailsForm.controls['lastName'].setValue(this.Candidate_Data.lastName);
    this.jobseekerBasicDetailsForm.controls['emailId'].setValue(this.Candidate_Data.emailId);
    this.jobseekerBasicDetailsForm.controls['phone_work'].setValue(this.Candidate_Data.phone_work);
    this.jobseekerBasicDetailsForm.controls['phone_home'].setValue(this.Candidate_Data.phone_home);
    this.jobseekerBasicDetailsForm.controls['phone_mobile'].setValue(this.Candidate_Data.contactNo);
    this.jobseekerBasicDetailsForm.controls['ssnNo'].setValue(this.Candidate_Data.ssnNo);
    // const obj = (this.Candidate_Data.dob === '' ? '' : this.Candidate_Data.dob.split('T'));
    // this.jobseekerBasicDetailsForm.controls["dob"].setValue((this.Candidate_Data.dob === '' ? '' : obj[0]));
    // this.jobseekerBasicDetailsForm.controls["dob"].setValue(this.datePipe.transform(this.Profilelist.dob, "MM/dd/yyyy"));
    this.jobseekerBasicDetailsForm.controls['country'].setValue(this.Candidate_Data.country);
    this.jobseekerBasicDetailsForm.controls['citizenship'].setValue(this.Candidate_Data.citizenship);
    this.jobseekerBasicDetailsForm.controls['zipCode'].setValue(this.Candidate_Data.zipCode);
    this.jobseekerBasicDetailsForm.controls['skill'].setValue(this.Candidate_Data.skill);
    this.jobseekerBasicDetailsForm.controls['experience'].setValue(this.Candidate_Data.experience);
    this.jobseekerBasicDetailsForm.controls['streetName'].setValue(this.Candidate_Data.streetName);
    this.countryChange()
      .then(stateList => {
        this.stateList = stateList;
        this.jobseekerBasicDetailsForm.controls['state'].setValue(this.Candidate_Data.state);
        this.stateChange()
          .then(cityList => {
            this.cityList = cityList;
            this.jobseekerBasicDetailsForm.controls['city'].setValue(this.Candidate_Data.city);
          })
          .catch(error => {
            this.tostr.errorToastr(error, 'Error!');
          })
      }).catch(error => {
        this.tostr.errorToastr(error, 'Error!');
      });

    if (this.isEditBasicform == false) {
      this.isEditform = !this.isEditform;
    }

  }

  get f() {
    return this.jobseekerBasicDetailsForm.controls;
  }

  get b() {
    return this.jobseekerDetailsForm.controls;
  }
  countryChange() {
    this.AuthenticationService.jwtTokenExpCheck();
    let CountryId: any = 0;
    this.countryList.forEach(element => {
      if (this.jobseekerBasicDetailsForm.controls['country'].value == element.name) {
        CountryId = element.Id
      }
    });
    return new Promise((resolve, reject) => {
      this.stateLoader = true;
      this.jobseekerApi.state(CountryId).subscribe((res: any) => {
        this.stateLoader = false;
        if (res.success) {
          this.stateList = res.data
          resolve(res.data);
        } else {
          reject('Error')
          // this.toastr.errorToastr(res.message, "Error!");
        }
      })
    })

  }


  stateChange() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.cityLoader = true;
    let stateId: any = 0;
    this.stateList.forEach(element => {
      if (this.jobseekerBasicDetailsForm.controls['state'].value == element.name) {
        stateId = element.Id
      }
    });

    return new Promise((resolve, reject) => {
      this.jobseekerApi.city(stateId).subscribe((res: any) => {
        this.cityLoader = false;
        if (res.success) {
          this.cityList = res.data;
          resolve(res.data)
        } else {
          reject('Error!')
          // this.toastr.errorToastr(res.message, "Error!");
        }
      })
    })
  }

  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }


  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }


  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  // updateBasicForm(){
  //   this.submit = true;
  //   if (this.jobseekerDetailsForm.valid) {
  //     this.jobseekerBasicDetailsForm.controls["skill"].setValue(this.jobseekerDetailsForm.controls["skill"].value);
  //     this.jobseekerBasicDetailsForm.controls["experience"].setValue(this.jobseekerDetailsForm.controls["experience"].value);
  //     this.jobseekerApi.jobseekerBasicDetails(this.jobseekerBasicDetailsForm.value,this.Candidate_Data.jobseekerId).subscribe(res => {
  //       this.isSuccess = res;
  //       if (this.isSuccess.success) {
  //         this.isEditBasicform = false;
  //         this.submit = false;
  //         this.tostr.successToastr(this.isSuccess.message, "", {
  //           position: "top-right"
  //         });
  //         this.loadCandidateDetails(this.JobID);
  //       } else {
  //         this.tostr.errorToastr(this.isSuccess.message, "", {
  //           position: "top-right"
  //         });

  //       }
  //     });
  //   }
  //   else {
  //     return false;
  //   }
  // }

  //isSuccess: any;
  // Update_Basic_Details() {
  //   this.submitted = true;
  //   if (this.jobseekerBasicDetailsForm.valid) {
  //     this.preLoader = true;
  //     this.jobseekerApi.jobseekerBasicDetails(this.jobseekerBasicDetailsForm.value,this.Candidate_Data.jobseekerId).subscribe(res => {
  //       this.isSuccess = res;
  //       if (this.isSuccess.success) {
  //         this.isEditform = false;
  //         this.preLoader = false;
  //         this.submitted = false;
  //         this.tostr.successToastr(this.isSuccess.message, "", {
  //           position: "top-right"
  //         });
  //         this.loadCandidateDetails(this.JobID);
  //       } else {
  //         this.tostr.errorToastr(this.isSuccess.message, "", {
  //           position: "top-right"
  //         });
  //         this.preLoader = false;
  //       }
  //     });
  //   }
  //   else {
  //     return false;
  //   }
  // }

  get ep() { return this.resetForm.controls; }
  resetPasswordform() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.jsSubmitted = false;
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      re_password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
    });
    this.resetForm.controls['password'].setErrors({ 'invalid': false });
    this.resetForm.controls['re_password'].setErrors({ 'invalid': false });
    this.notMatch = false;
    this.isAddform = !this.isAddform;
  }


  // Submit reset password
  jsSubmitted = false;
  onSubmit() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.jsSubmitted = true;
    if (this.resetForm.controls['password'].value === this.resetForm.controls['re_password'].value) {
      this.notMatch = false;
      if (this.resetForm.valid) {
        this.isAddform = false;
        this.preloader = true;
        this.loading = true;
        try {
          let params = {
            Email: this.Candidate_Data.emailId,
            Password: this.resetForm.controls['password'].value,
            code: ''
          };

            this.tableService.resetPasswordVDInAdmin(params).subscribe((res: any) => {
            this.loading = false;
            this.preloader = false;
            if (res.success) {
              this.isAddform = false;
              this.tostr.successToastr(res.message, 'Done!');
            } else {
              this.isAddform = true;
              this.tostr.errorToastr(res.message, 'Error!');
            }
          });
        } catch (error) {
          this.preloader = false;
          throw error;
        }
        this.preloader = false;
        this.isAddform = false;
      } else {
        this.isAddform = true;
        this.preloader = false;
        return false;
      }
    } else {
      this.notMatch = true;
      return false;
    }
  }

//Akhilesh:20200912, file check exits on root folder
  ImageFileExits(imgname) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.jobseekerApi.fileExists("profileimage",imgname).subscribe((res: any) => {
      if (res.data == true) {
        this.isImgExits = true;
      }
    });
  }
//Akhilesh:20200912, file check exits on root folder
  ResumeFileExits(resumename) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.jobseekerApi.fileExists("resume",resumename).subscribe((res: any) => {
      if (res.data == true) {
        this.isResumeExits = true;
      }
    });
  }

  // Get resume path
  JobseekerResume() {
    this.preLoader = true;
    //let id = this.JobID.substring(6);
    this.AuthenticationService.jwtTokenExpCheck();
      this.jobseekerApi.jobseekerProfileResume(this.JobID).subscribe(res => {

        this.response = res;
        if(this.response.data.length > 0){
        var resumename = this.resume_name = this.response.data[0].fileName;
        var resumepath = this.resume_path = this.response.data[0].filePath;
        localStorage.setItem('resumename',  resumename);
        localStorage.setItem('resumepath',  resumepath);
        this.preLoader = false;
      }
    });
  }

  confirm() {
    this.AuthenticationService.jwtTokenExpCheck();
     const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
       width: 'auto',
       //disableClose: true,
       hasBackdrop: false,
       data: {Email: this.Candidate_Data?.emailId}
     });
     dialogRef.afterClosed().subscribe(result => {
       //console.log('The dialog was closed');
       if (result != 'close') {
       }
     });
  }

  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public togglePasswordVisibility(type:Boolean): void {
    if(type){
      this.showPassword = !this.showPassword;
    }
    else{
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
}
