import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { EnterpriseService } from '../enterprise.service'
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-invoice-view-details',
  templateUrl: './invoice-view-details.component.html',
  styleUrls: ['./invoice-view-details.component.css']
})
export class InvoiceViewDetailsComponent implements OnInit {
  preloader: boolean = false;
  invoiceId: string;
  InvoiceDetail: any;
  constructor(private _ActiveRouter: ActivatedRoute, private epAPI: EnterpriseService,private _titleService  :Title) {
    // for title set
    var newTitle = 'Virtusa | Invoice-ViewDetails';
    this._titleService.setTitle( newTitle );
    this.invoiceId = this._ActiveRouter.snapshot.params['id'];
  }

  ngOnInit() {
    this.getInvoiceDetails();
  }

  getInvoiceDetails() {
    this.preloader = true;
    this.epAPI.invoicedetails(this.invoiceId).subscribe((res: any) => {
      this.InvoiceDetail = res;
      if (this.InvoiceDetail.success) {
        this.preloader = false
      }
    });
  }

  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

}
