import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RouterLink, Router } from '@angular/router';
import { EnterpriseService } from '../enterprise.service';
import { AuthenticationService } from '../../app.service'
import { Title } from '@angular/platform-browser';
import { staffingAgencyServices } from '../../staffing-agency/staffing-agency.services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
//import { ResetWarningPopUpComponent } from 'src/app/share-module/reset-warning-pop-up/reset-warning-pop-up.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enterprise-dashboard',
  templateUrl: './enterprise-dashboard.component.html',
  styleUrls: ['./enterprise-dashboard.component.css']
})
export class EnterpriseDashboardComponent implements OnInit {
  preLoader: boolean = false;
  enterpriserData: any;
  enterpriseDataShow: boolean = false;
  recentAct: boolean = false;
  rescetActivityData: any;
  announcShow: boolean = false;
  announceData: any;
  img_path: string = '';
  staffDate: any;
  userData: any;
  staffDataShow=false;
  paginator = {
    RA: {
      pageSize: 5,
      length: 0,
      pageSizeOptions: [5, 10, 20, 100]
    }, AN: {
      pageSize: 5,
      length: 0,
      pageSizeOptions: [5, 10, 20, 100]
    }
  };

  userDataCheck: any = {};
  todayDate= new Date;
  dbdate=new Date;
  diffDays: number;

  @Output() myEmitter = new EventEmitter()
  announcementsModel: any;

  constructor(
    private myrouter: Router,
    private eterpriseAPI: EnterpriseService,
    private API: AuthenticationService,
    private _titleService: Title,
    private toastr: ToastrManager,
    config: NgbModalConfig, private datePipe: DatePipe, public dialog: MatDialog,
     private modalService: NgbModal
  ) {
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    config.backdrop = 'static';
    config.keyboard = false;
    var newTitle = 'Virtusa | Dashboard';
    this._titleService.setTitle( newTitle );
    this.preLoader = true;
    this.myEmitter.emit('true');
  if(this.userData.data.credentials.Role=='ClientHiringManager'){
    this.eterpriseAPI.ClientHiringManagerDashboard().subscribe(res => {
      this.enterpriserData = res;
      this.img_path = `${this.API.url}/api/enterprise/image/profileimage/${this.enterpriserData.data[0].profileImage}`;
      this.data = [{
        kind: '', share: 100, color: '#dddddd'
      }, {
        kind: '', share: this.enterpriserData.data[0].jobsuccessrate,
        color:'#f01860'
      }];

      this.preLoader = false;
      this.enterpriseDataShow = true;
    });

    this.eterpriseAPI.ClientHiringManagerDashboard().subscribe((res: any) => {

          if (res.success) {

        this.staffDate = res;
        this.img_path = `${this.API.url}/api/staffingagency/image/profileimage/${this.staffDate.data[0].profileImage}`;
        this.data = [
          {
            kind: '',
            share: 100,
            color: '#dddddd'
          },
          {
            kind: '',
            share: this.staffDate.data[0].jobsuccessrate,
            color: '#3174bc'
          }
        ];
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
        this.staffDate = JSON.parse(JSON.stringify(localStorage.getItem('udata')));
      }
      this.preLoader = false;
      this.staffDataShow = true;
    });
  }
  else{
    this.eterpriseAPI.enterpriseDashboard().subscribe(res => {

            this.enterpriserData = res;
            this.img_path = `${this.API.url}/api/enterprise/image/profileimage/${this.enterpriserData.data[0].profileImage}`;
            this.data = [{
              kind: '', share: 100, color: '#dddddd'
            }, {
              kind: '', share: this.enterpriserData.data[0].jobsuccessrate,
              color:'#f01860'
            }];

            this.preLoader = false;
            this.enterpriseDataShow = true;
          });

          this.eterpriseAPI.enterpriseDashboard().subscribe((res: any) => {
              if (res.success) {
              this.staffDate = res;
              this.img_path = `${this.API.url}/api/staffingagency/image/profileimage/${this.staffDate.data[0].profileImage}`;
              this.data = [
                {
                  kind: '',
                  share: 100,
                  color: '#dddddd'
                },
                {
                  kind: '',
                  share: this.staffDate.data[0].jobsuccessrate,
                  color: '#3174bc'
                }
              ];
            } else {
              this.toastr.errorToastr(res.message, 'Error!');
              this.staffDate = JSON.parse(JSON.stringify(localStorage.getItem('udata')));
            }
            this.preLoader = false;
            this.staffDataShow = true;
          });

  }
    this.userDataCheck = JSON.parse(localStorage.getItem('udata'));
    let newDate = new Date(this.datePipe.transform(this.userDataCheck.data.credentials.UpdatePwdDate, 'yyyy-MM-dd'));
    var diff = Math.abs(this.todayDate.getTime() - newDate.getTime());
     this.diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    if(this.diffDays>environment.passwordExpiredays) {
    }
  }

  public data: any[] = [{
    kind: '', share: 100,
    color: '#ddd'
  }, {
    kind: '', share: 100,
    color:'#f01860'
  }];

  public labelContent(e: any): string {
    return e.category;
  }
  public chartType: string = 'doughnut';

  public chartDatasets: Array<any> = [
    {
      data: [300, 50, 100, 40, 120.100, 120, 30],
      holeSize: 10,
      padding: 3,
      font: 'bold 16px Arial, sans-serif',
      label: 'My First dataset'
    }
  ];

  public chartLabels: Array<any> = ['Red', 'Green', 'Yellow', 'Grey', 'Dark Grey'];

  public chartColors: Array<any> = [
    {
      backgroundColor: ['#105092', '#105092', '#105092', '#105092', '#105092'],
      hoverBackgroundColor: ['#105092', '#105092', '#105092', '#105092', '#105092'],
      borderWidth: 10,
    }
  ];

  public chartOptions: any = {
    responsive: true
  };
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }
  ngOnInit() {
  }

  Popupstaffingform() { }

  openAnnouncementsModel(content, data) {
    this.announcementsModel = data;
    this.modalService.open(content);
  }

  suppliers(){
    this.myrouter.navigateByUrl("/ep/enterprise/staffingagency");
  }
}

