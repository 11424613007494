// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  verisourceUrl:"https://vendors.virtusa.com",
   //endPoint: 'https://verisourceapi-staging.veriklick.in',
   //url : 'https://verisourceapi-staging.veriklick.in',
  endPoint: 'https://vsapi-v4.veriklick.in',
  url : 'https://vsapi-v4.veriklick.in',
  // endPoint: 'http://localhost:1256',
  // url : 'http://localhost:1256' ,
  passwordExpiredays:180,
  passwordExpireAlert:7,
  refreshTokenMaxTime:5,
  refreshTokenMinTime:2,
  apipath:'https://veridialapivs.veriklick.com/api/v1/',
  // apipath:'http://localhost:5000/api/v1/',
  EncryptKey: 'Synk@1234',
  VeridialSkillId:'127',
  VeridialPermissionId:108,
  AppRefTypeClient:'VKEP',
  AppRefTypeSupplier:'VKSA',
  AppRefTypeJobseeker:'VKJS',
  AppRefTypeAdmin:'AD',
  AppType:'FF658957-D9E7-4B71-973B-3889004C70AE',
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
