import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
})
export class authComponentClass implements OnInit {

    constructor(private _titleService:Title ) {
    var newTitle = 'Virtusa | Home';
    this._titleService.setTitle( newTitle );
     }

    ngOnInit() {
    }

}
