import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validator, Validators, RequiredValidator } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ShareService } from '../share.service';
import { Title } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { IfStmt } from '@angular/compiler';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';
import {AuthenticationService } from '../../app.service';
import { DatePipe } from '@angular/common';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { environment } from 'src/environments/environment';
import { TableService } from 'src/app/admin/service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  statusList = [
    { name: 'Closed', value: 'Closeed' },
    { name: 'Pending', value: 'Pending' },
    { name: 'Re-Open', value: 'Re-Open' }
  ];
  dataLoad = false;
  Admin_Img_Path: string = '/assets/img/user.svg';
  img_path  = '/assets/images/user.svg';
  showData = false;
  imgName: any;
  isreplysuccess: any;
  isImgExits: boolean;
  serverpth  = '';
  @Input() userType: string = "";
  userData: any;
  preLoader = false;
  issubmitbtn: boolean = false;
  reply_status: any;
  tokenId: any;
  submited: boolean = false;
  loginStatus: string;
  contactForm: FormGroup;
  contactuslistForm: FormGroup;
  contactlist_replymessage: any;
  listcontact_replymessage: any;
  contactusForm: FormGroup;
  issubmitfrom: boolean = false;
  issubmitaddfrom: boolean = false;
  submitted: boolean = false;
  issuccess: any;
  preloader: boolean = false;
  resetcontactusform: any;
  @Input() createdBy: string = "";
  paginator = {
    pageSize: 10,
    length: 0,
    pageSizeOptions: [5, 10, 20, 100],
    pageIndex: 0
  };
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];
  //email regex for example@abc.com
  //^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,3}$
  emailvalidator = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,3}(\\.[a-zA-Z]{2,2})?(\\.[a-zA-Z]{2,2})?$'
  Profilelist: any;
  AdminDetails: any;
  select_img_path = '/assets/images/user.svg';
  ProfileData: any;
  E_Img_Path: String = '';
  adminId: any;
  constructor(private _formBuilder: FormBuilder,  private staffApi: staffingAgencyServices,   private EnterpriserApi: EnterpriseService,
     private toastr: ToastrManager, private shareApies: ShareService, private _titleService : Title,
    private AuthenticationService: AuthenticationService, private shareservice:ShareService,
    private api: TableService,private datePipe: DatePipe,
    ) {

      this.serverpth = `${this.AuthenticationService.url}`;
      this.userData = JSON.parse(window.localStorage.getItem('udata'));
      if(this.userData != null){
        this.adminId = this.userData.data.credentials.CreatedBy
        this.select_img_path = localStorage.getItem('selectprofileImagePath');
        if(this.select_img_path != null){
          this.img_path = this.select_img_path;
        }else {
          this.img_path = localStorage.getItem('profileImagePath');
          this.select_img_path = this.img_path;
        }
        this.profileImage(this.adminId);
        this.profilefuction();
      }
    var newTitle = 'Virtusa | Contact Us';
    this._titleService.setTitle(newTitle);
    this.contactusForm = this._formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(this.emailvalidator)]],
      subject: ['', [Validators.required]],
      phone: ['', Validators.required],
      message: ['', [Validators.required,Validators.maxLength(2000)]],
      createdDate:['']
    });



    this.contactuslistForm = this._formBuilder.group({
      PageSize: 10,
      PageNumber: 1,
      user_type: 'GN',
      search: '',
      fromdate: '',
      todate: '',
      status:'All'
    });

    this.contactForm = this._formBuilder.group({
      name: '',
      email: '',
      subject: '',
      phone: '',
      message: '',
      reply: '',
      support_status: '',
      createdDate:['']

    });

    this.loginStatus = localStorage.getItem('islogin')
  }

  ngOnInit() {
    if (this.loginStatus == 'true') {
      this.profilefuction();
      // console.log(this.userType);
      this.loadcontacts_replymessage();
      this.loadcontactslist();
      this.LoadAdminProfile();
    }
  }
  get fcu() { return this.contactusForm.controls; }
  get f() { return this.contactForm.controls; }
  SaveContactUs() {
    this.AuthenticationService.jwtTokenExpCheck();
    if (this.loginStatus == 'false') {
      this.userType = 'GN';
    }
    this.submitted = true;
    if (this.contactusForm.valid) {
      this.contactusForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
      //Manu Datta: 20201212, CommonLogin 144
      //Manu Datta: 20210309, VerisourceBugSheetAfterMerge/commonLogin 435

      //Manu Datta: 20210318, VerisourceBugSheetAfterMerge/commonLogin 467
      if (this.userData.data.credentials.CreatedBy){
        this.createdBy = this.userData.data.credentials.CreatedBy;
      }else{
        this.createdBy = this.userData.data.credentials.userId;
      }
      this.shareApies.contactus(this.userType,this.createdBy, this.contactusForm.value).subscribe(res => {
        // console.log(res);
        this.issuccess = res;
        if (this.issuccess.success) {
          this.issubmitaddfrom = false;
          this.loadcontactslist();
          this.toastr.successToastr(this.issuccess.message, '', { position: 'bottom-right' });
          this.contactusForm.reset();
          this.contactusForm.controls['email'].setValue(this.Profilelist.emailId);
          this.contactusForm.controls['phone'].setValue(this.Profilelist.contactNo);
          this.contactusForm.controls['email'].setValue(this.Profilelist.data.emailId);
          this.contactusForm.controls['phone'].setValue(this.Profilelist.data.contactNo);
          this.submitted = false;
        } else {
          this.toastr.errorToastr(this.issuccess.message, '', { position: 'bottom-right' });
        }
      });
    } else {
      // this.submitted = false;
    }
  }

  OnChangePhoneNo(e) {
    var r = /(\D+)/g,
      npa = '',
      nxx = '',
      last4 = '';
    e.value = e.target.value.replace(r, '');
    npa = e.value.substr(0, 3);
    nxx = e.value.substr(3, 3);
    last4 = e.value.substr(6, 4);
    if (e.target.value != '' && e.keyCode != 8) {
      this.contactusForm.controls['phone'].setValue(npa + '-' + nxx + '-' + last4);
    }
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  onclickcancel(formtype) {
    this.AuthenticationService.jwtTokenExpCheck();
    if (formtype == 'contactus') {
      this.issubmitaddfrom = false;
    }
    else if (formtype == 'contactpersonreply') {
      this.issubmitfrom = false;
    }
  }
  onclickshowform(contact) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.issubmitfrom = true;
    this.tokenId = contact.tokenId;
    this.contactForm.controls['name'].setValue(contact.yourName);
    this.contactForm.controls['email'].setValue(contact.useremailId);
    this.contactForm.controls['subject'].setValue(contact.subject);
    this.contactForm.controls['phone'].setValue(contact.phone);
    this.contactForm.controls['message'].setValue(contact.reply_message);
    this.loadcontacts_replymessage();
    this.loadcontactslist();
  }

  loadcontacts_replymessage() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.preloader = true;
    if (this.loginStatus == 'true') {
      this.userType = this.userData.data.credentials.userType;
      this.shareApies.loadallcontacts_replymessage(this.userType, this.tokenId).pipe(first()).subscribe(res => {
        if (res.success) {
          this.preloader = false;
          this.listcontact_replymessage = res.data;
          this.reply_status = res.data[0].support_status;
        }
      });
    }
  }



  loadcontactslist() {

    this.AuthenticationService.jwtTokenExpCheck();
    if (this.userData.data.credentials.userType == 'EP') {
      this.contactuslistForm.controls['user_type'].setValue('EP');

    }
    else if (this.userData.data.credentials.userType === 'SA') {
      this.contactuslistForm.controls['user_type'].setValue('SA');

    }

    // this.contactuslistForm.controls["fromdate"].setValue(this.datePipe.transform(this.contactuslistForm.controls["fromdate"].value, "yyyy-MM-dd"))
    // this.contactuslistForm.controls["todate"].setValue(this.datePipe.transform(this.contactuslistForm.controls["todate"].value, "yyyy-MM-dd 23:59:59"))

    // if(this.searchButton == true){
    //   this.contactuslistForm.controls['PageNumber'].setValue(1);
    // }
    // this.contactuslistForm.value.fromdate=(this.shareservice.convertDateIntoUtc( this.contactuslistForm.controls["fromdate"].value))
    // this.contactuslistForm.value.todate=(this.shareservice.convertDateIntoUtc( this.contactuslistForm.controls["todate"].value))


    // this.contactuslistForm.controls['PageSize'].setValue(10);
    // this.contactuslistForm.controls['PageNumber'].setValue(1);
    this.preloader = true;
    if (this.loginStatus == 'true') {
      // this.contactuslistForm.controls["fromdate"].setErrors(null)
       this.contactuslistForm.controls["todate"].setErrors(null)
      this.shareApies.loadallcontactslist(this.contactuslistForm.value).pipe(first()).subscribe(res => {

        if (res.success) {
          //this.showData=true;
          this.preloader = false;
          this.contactlist_replymessage = res.data;

        //   if(this.searchButton == true)
        // {
        //   this.paginators.pageIndex = 0;
        // }
        // this.searchButton = false;

        if (res.data.length > 0) {
          this.paginator.length = res.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }

        }
      });
    }
  }

  setpaggination(e) {

    // this.searchButton = false;
    // this.contactuslistForm.controls['PageSize'].setValue(e.pageSize);
    // this.contactuslistForm.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.contactuslistForm.controls['PageSize'].setValue(e.pageSize);
    this.contactuslistForm.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.loadcontactslist();
  }

  onclickshowaddform() {
    this.issubmitaddfrom = true;

  }
  onclickshowtbn(tab_no: number) {
    if (tab_no === 1) {
      this.contactForm.controls['reply'].setValue('');
      this.issubmitbtn = true;
    } else if (tab_no === 2) {
      this.issubmitbtn = false;
    }
    else if (tab_no === 0) {
      this.issubmitfrom = false;
    }
  }

  Queryreply() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.submited = true;
    //console.log('invalid');
    if (this.contactForm.valid) {
      //console.log('valid');
      this.contactForm.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()))
      this.shareApies.replytoquery(this.contactForm.value, this.tokenId).pipe(first()).subscribe(res => {
        this.isreplysuccess = res;
        if (this.isreplysuccess.success) {
          this.statusList;
          this.issubmitbtn = false;
          this.loadcontacts_replymessage();
          this.loadcontactslist();
          this.toastr.successToastr(this.isreplysuccess.message, 'Success!');
        } else {
          this.toastr.errorToastr(this.isreplysuccess.message, 'OPPS!')
        }
      });
    }
  }




  profilefuction() {
    if (this.userData.data.credentials.userType == 'SA') {
      this.staffApi.staffingdetails().pipe(first()).subscribe(res => {

        this.isreplysuccess = res;
        if (this.isreplysuccess.success) {
          this.Profilelist = res;
          this.contactusForm.controls['email'].setValue(this.Profilelist.data.emailId);
          this.contactusForm.controls['phone'].setValue(this.Profilelist.data.contactNo);
          this.img_path = `${this.serverpth}/api/staffingagency/image/profileimage/${this.Profilelist.data.profileImage}`;
          this.imgName = this.Profilelist.data.profileImage;
          this.ImageFileExits(this.Profilelist.data.profileImage);
        } else {
          this.toastr.errorToastr(this.isreplysuccess.message, 'OPPS!')
        }
      });
    }
    else if (this.userData.data.credentials.userType == 'EP') {
      //this.preLoader = true;
      this.staffApi.Entrpzdetails().pipe(first()).subscribe(res => {
        this.isreplysuccess = res;
        if (this.isreplysuccess.success) {
          this.Profilelist = res;
          this.contactusForm.controls['email'].setValue(this.Profilelist.data.emailId);
          this.contactusForm.controls['phone'].setValue(this.Profilelist.data.contactNo);
          this.img_path = `${this.serverpth}/api/staffingagency/image/profileimage/${this.Profilelist.data.profileImage}`;
          this.imgName = this.Profilelist.data.profileImage;
          this.ImageFileExits(this.Profilelist.data.profileImage);
        } else {
          this.toastr.errorToastr(this.isreplysuccess.message, 'OPPS!')
        }
      });

    }
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }
// 20200929: Akhilesh, Special charcter validation, jira no 70
  omit_validate_specialchar(event: { charCode: any; }) {
    const k = event.charCode;
     if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91
      || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
       this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
       return false } }

//Akhilesh:20201009, file check exits on root folder
ImageFileExits(imgname) {
  this.EnterpriserApi.fileExists("profileimage",imgname).subscribe((res: any) => {
    if (res.data == true) {
      this.isImgExits = true;
    }
  });
}

LoadAdminProfile() {
  this.preloader = true;
  this.staffApi.adminMyProfile().pipe(first()).subscribe(res => {
    if(res.success) {

      this.showData=true;
      this.preloader = false;
      this.AdminDetails = res;
      if (this.AdminDetails.data.profileImage != null) {
        this.Admin_Img_Path = `${environment.url}/api/admin/image/profileimage/${this.AdminDetails.data.profileImage}`;
        this.imgName = this.AdminDetails.data.profileImage;
       // this.ImageFileExits();
      }

    }
  });
}

clearjobseekerList(){
  this.today = new Date().toISOString().split('T')[0];
  this.from_Date = null;
  this.contactuslistForm = this._formBuilder.group({
    PageSize: 10,
    PageNumber: 1,
    user_type: 'GN',
    search: '',
    fromdate: '',
    todate: '',
    status:'All'
  });
  //this.preloader = false;
  this.paginator.pageSize=10;
  this.loadcontactslist();
  //this.paginators.pageIndex = 0;
}

from_Date:String;
dateForToDate(type:number){
  if(type == 1){
    this.from_Date = new Date(this.datePipe.transform(this.contactuslistForm.controls['fromdate'].value)).toISOString();
  }

  if(type == 2){
    this.today = new Date(this.datePipe.transform(this.contactuslistForm.controls['todate'].value)).toISOString();
  }
}

//
profileImage(id) {

  this.preloader = true;
    this.EnterpriserApi.profileImageVD(id).subscribe(res => {
      this.ProfileData = res;
      if(this.ProfileData.data != null && this.ProfileData.data.length > 0){
       this.E_Img_Path =this.ProfileData.data[0].profileImagePath;
      this.preloader = false;
      }
    });
}
}
