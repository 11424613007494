import { Component, OnInit } from "@angular/core";
import {
  Router,
  RouterLink,
  RouterModule,
  ActivatedRoute
} from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  FormGroup,
  FormBuilder,
  Validator,
  Validators,
  RequiredValidator
} from "@angular/forms";
import { ToastrManager } from "ng6-toastr-notifications";
import { from, Subscription } from "rxjs";
import { Title } from '@angular/platform-browser';
import { TableService } from 'src/app/admin/service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import * as moment from 'moment';
import { ShareService } from '../share.service';

@Component({
  selector: "app-edit-job-list",
  templateUrl: "./edit-job-list.component.html",
  styleUrls: ["./edit-job-list.component.css"]
})
export class EditJobListComponent implements OnInit {
  client_id:any;
  createdby:any;
  checkdescription:boolean =false;
  isApprovalPopUp: boolean = false;
  routeSub: Subscription;
  projectId: any = undefined;
  totalexceed: number = 0;
  totalalljobBudget: number = 0;
  projDetail: any;
  jobAmount: number = 0;
  pretotalAmount: number = 0;
  isRequired: false;
  comparevalError: any;
  payComparevalError: any;
  minError: any;
  subList: any;
  asynlist = false;
  maxError: any;
  JobData: any;
  jobId: any;
  submitted: boolean = false;
  enterpriseEditjob: FormGroup;
  updateresponse: any;
  preLoader: boolean = false;
  listShow: boolean = false;
  candidateList: any;
  tabAll: boolean = true;
  isCountrySuccess: boolean = false;
  ListOfAllCountries: any;
  isStateSuccess: boolean = false;
  ListOfAllStates: any;
  isCitiesSuccess: boolean = false;
  ListOfAllCities: any;
  isSearchCitiesSuccess: boolean = false;
  SearchListOfAllCities: any;
  companies: any[] = [];
  loading = false;
  companiesNames = [];
  monthlist = [];
  today: String = new Date().toISOString().split('T')[0];
  userData: any = {};
  isRequire = false;
  RateOption = [{ name: 'W-2', value: 'W-2' }, { name: '1099', value: '1099' }, { name: 'C2C', value: 'C2C' }];
  allStateList: Object;
  allCityList: Object;
  state_Id: any;
  country_Id: any;
  clientId:string;
  Logintype: string;
  constructor(
    private enterpriseApis: TableService,
    private enterpriseApi: EnterpriseService,
    private router: Router,
    private datePipe: DatePipe,
    private ActiveRouter: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private toastr: ToastrManager,
    private _titleService: Title,
    private shareservice:ShareService
  ) {

    // for title set
    var newTitle = 'Virtusa | Edit-JobList';
    this._titleService.setTitle(newTitle);

    this.jobId = this.ActiveRouter.snapshot.params.jobId;
    this.userData = JSON.parse(window.localStorage.getItem('udata'));
    if (this.userData.data.credentials.userType == 'EP') {
      this.isRequire = true;
    }
    this.enterpriseEditjob = this._formBuilder.group({
      "jobTitle": ['', [Validators.required]],
      "jobLocation": ["", [Validators.required]],
      "jobCountry": ["", Validators.required],
      "totalOpenings": ["", Validators.required],
      "requiredExpLevelMin": [""],
      "requiredExpLevelMax": [""],
      "jobType": ["Contract Basis"],
      "jobDuration": ["", Validators.required],
      "overTimeAllow": ["No", Validators.required],
      "overTimePayRate": [],
      "overTimeMax": [],
      "totalJobBudget": [],
      'contractRate': ['W-2'],
      "hiringManager": [""],
      "hiringManagerName": [""],
      "annualPayMin": ["", [Validators.required, Validators.minLength(1)]],
      "annualPayMax": ["", [Validators.required, Validators.minLength(1)]],
      "joiningDate": ["", Validators.required],
      "jobDescription": ["",[Validators.required]],
      "description": [""],
      "country": [""],
      "state": [""],
      "city": [""],
      "postalcode": [""],
      "street1": ["",Validators.required],
      "street2": [""],
      "skills": ["", [Validators.required]],
      "keywords": "",
      "note": [""],
      "clientId":[""],
      "createdDate":[""],
      "createdBy":[''],
      'registerBy':[''],
      'updatedBy':['']
    });

    for (let i = 1; i <= (50); i++) {
      this.monthlist.push(i);
    }
    this.LoadAllCountries();

  }



  sub: any;
  page: number;

  ngOnInit() {

    this.sub = this.ActiveRouter
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.page = +params['project'] || 0;
        //console.log('page=====>', this.page)
      });
    this.routeSub = this.ActiveRouter.params.subscribe(params => {
      this.projectId = params['id'];
      if(this.userData.data.credentials.userType == 'AD'){
        this.clientId = params['clientId'];
      }
    });
    this.LoadAllCountries();
    this.LoadSingleJob(this.jobId);
    this.subadminList();
    if (this.projectId != undefined) {
      this.getProjectDetails();
      this.getProjectJobsByProjectId();
    }
  }

  getProjectDetails() {
    //this.preloader = true;
    this.enterpriseApis.getProjectById(this.projectId).subscribe((res: any) => {
      this.projDetail = res.data;
      //this.preloader = false;
      if (res.success) {
        if (res.data.length > 0) {
          //this.projectDetails = res.data[0];
          this.totalexceed = +res.data[0].totalAllocatedBudgetAmt;
        }
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  getProjectJobsByProjectId() {
    //this.preloader = true;
    this.enterpriseApis.getProjectJobsByProjectId(this.projectId).subscribe((res: any) => {
      //this.preloader = false;
      if (res.success) {
        //this.projectJobs = res.data;

        for (var i = 0; i < res.data.length; i++) {
          let sum = res.data[i].totalOpenings * res.data[i].annualPayMax * res.data[i].jobDuration * 160;
          this.totalalljobBudget += sum;
        }
      } else {
        this.toastr.errorToastr(res.message);
      }
    });
  }

  get aj() {
    return this.enterpriseEditjob.controls;
  }
  Ajsubmitted: boolean = false;
  joiningdateshow:any;
  setFormValue(jobdata) {

    let location_List = jobdata.jobLocation.split(",");
    this.enterpriseEditjob.controls["jobTitle"].setValue(jobdata.jobTitle);
    this.enterpriseEditjob.controls["jobLocation"].setValue(location_List);
    this.enterpriseEditjob.controls["totalOpenings"].setValue(jobdata.totalOpenings);
    this.enterpriseEditjob.controls["requiredExpLevelMax"].setValue(jobdata.requiredExpMax);
    this.enterpriseEditjob.controls["requiredExpLevelMin"].setValue(jobdata.requiredExpMin);
    this.enterpriseEditjob.controls["jobType"].setValue(jobdata.jobType);
    if (jobdata.jobType === 'Contract Basis') {
      this.isShowJobDuration = true;
      this.enterpriseEditjob.controls["contractRate"].setValue(jobdata.contractRate);
    } else {
      this.isShowJobDuration = false;
    }

    // var d = new Date(jobdata.joiningDate); // val is in UTC
    // var localOffset = d.getTimezoneOffset() * 60000;
    // var localTime = d.getTime() - localOffset;
    // d.setTime(localTime);
    this.joiningdateshow =new Date(jobdata.joiningDate).toLocaleString();
    this.enterpriseEditjob.controls["jobDuration"].setValue(parseInt(jobdata.jobDuration));
    this.enterpriseEditjob.controls["hiringManager"].setValue(jobdata.hiringManager);
    this.enterpriseEditjob.controls["hiringManagerName"].setValue(jobdata.hiringManagerName);
    this.enterpriseEditjob.controls["annualPayMax"].setValue(jobdata.annualPayMax);
    this.enterpriseEditjob.controls["annualPayMin"].setValue(jobdata.annualPayMin);
    this.enterpriseEditjob.controls["joiningDate"].setValue(this.datePipe.transform(jobdata.joiningDate, "yyyy-MM-dd"));
    if (jobdata.overTimeAllow == true) {
      this.enterpriseEditjob.controls["overTimeAllow"].setValue('Yes');
      this.isShow = true;
    }
    else {
      this.enterpriseEditjob.controls["overTimeAllow"].setValue('No');
      this.isShow = false;
    }

    this.enterpriseEditjob.controls["overTimePayRate"].setValue(jobdata.overTimePayRate);
    this.enterpriseEditjob.controls["overTimeMax"].setValue(jobdata.overtimeMaximum);
    this.enterpriseEditjob.controls["totalJobBudget"].setValue(jobdata.totalJobBudget);
    this.enterpriseEditjob.controls["jobDescription"].setValue(jobdata.jobDescription);

    this.enterpriseEditjob.controls["jobCountry"].setValue(jobdata.jobCountry);


    let keywords_List = jobdata.keywords !== '' ? jobdata.keywords.split(",") : jobdata.keywords;
    this.enterpriseEditjob.controls["skills"].setValue(keywords_List);

    this.enterpriseEditjob.controls["note"].setValue(jobdata.note);

    this.enterpriseEditjob.controls["postalcode"].setValue(jobdata.postalcode);
    this.enterpriseEditjob.controls["street1"].setValue(jobdata.street1);
    this.enterpriseEditjob.controls["street2"].setValue(jobdata.street2);


    this.LoadAllCountries().then(countryData => {
      this.ListOfAllCountries = countryData;
      this.enterpriseEditjob.controls["country"].setValue(jobdata.country);
      this.LoadAllState()
        .then(stateList => {

          let stateId;
          this.ListOfAllStates = stateList;
          this.enterpriseEditjob.controls["state"].setValue(jobdata.state);
          if (this.ListOfAllStates.data.length > 0) {
            this.ListOfAllStates.data.forEach(element => {
              if (element.name == this.enterpriseEditjob.controls['state'].value) {
                stateId = element.Id;
              }
            });
          }
          this.LoadAllCities(stateId).then(cities => {

            this.ListOfAllCities = cities;
            this.enterpriseEditjob.controls["city"].setValue(jobdata.city);
          }).catch(err => {
            this.toastr.errorToastr(err, "Error!");
          });
        }).catch(err => {
          this.toastr.errorToastr(err, "Error!")
        });
    }).catch(err => {

    });
    let prejobDuration = this.enterpriseEditjob.controls["jobDuration"].value
    let premaxPay = this.enterpriseEditjob.controls["annualPayMax"].value
    let pretotalOpening = this.enterpriseEditjob.controls["totalOpenings"].value

    this.pretotalAmount = prejobDuration * premaxPay * pretotalOpening * 160
  }

  LoadSingleJob(jobid) {

    this.preLoader = true;
    this.enterpriseApis.LoadSingleJob(jobid).subscribe((res: any) => {
      if (res.success) {
        this.JobData = res;
        this.preLoader = false;
        this.setFormValue(this.JobData.data);
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
        this.preLoader = false;
      }
    });
  }

  isShowJobDuration: boolean = true;
  disableHiringManager=false;
  onChange_JobType(feild_no) {
    if (feild_no == 1) {
      this.isShowJobDuration = true;
      this.disableHiringManager = false;
      this.isRequire = true;
    }
    else if (feild_no == 2) {
      this.isShowJobDuration = false;
      this.disableHiringManager = true;
      this.isRequire = false;
    }
  }
  addTag(name) {
    return { name: name, tag: true };
  }

  addTagPromise(name) {
    return new Promise((resolve) => {
      this.loading = true;
      setTimeout(() => {
        resolve({ id: 5, name: name, valid: true });
        this.loading = false;
      }, 1000);
    });
  }
  LoadAllCountries() {

    return new Promise((resolve, reject) => {

      this.enterpriseApis.LoadAllCountriesApi().subscribe(res => {
        this.ListOfAllCountries = res;
        if (this.ListOfAllCountries.success) {
          this.isCountrySuccess = true;
          resolve(this.ListOfAllCountries);
        }
        else {
          reject("Error!");
        }
      });

    });

  }

  // LoadAllState(countryId) {
  //   this.enterpriseApis.LoadAllStateApi(countryId).subscribe(res => {
  //     this.ListOfAllStates = res;
  //     if (this.ListOfAllStates.success) {
  //       this.isStateSuccess = true;
  //     }
  //   });
  // }
  // LoadAllCities(stateId) {

  //   this.enterpriseApis.LoadAllCitiesApi(stateId).subscribe(res => {

  //     this.ListOfAllCities = res;
  //     if (this.ListOfAllCities.success) {
  //       this.isCitiesSuccess = true;
  //     }
  //   });
  // }




  LoadAllState() {
    //let countryId = '';
    if (this.ListOfAllCountries.data.length > 0) {
      this.ListOfAllCountries.data.forEach(element => {
        if (element.name == this.enterpriseEditjob.controls['country'].value) {
          this.country_Id = element.Id;
        }
      });
    }

    return new Promise((resolve, reject) => {
      this.enterpriseApis.LoadAllStateApi( this.country_Id).subscribe(res => {
        this.ListOfAllStates = res;
        this.allStateList=res;
        if (this.ListOfAllStates.success) {
          resolve(this.ListOfAllStates);
          this.isStateSuccess = true;
        } else {
          reject("Error!");
        }
      });
    });

  }
  LoadAllCities(stateId) {
    this.ListOfAllStates.data.forEach(element => {
      if (element.name == this.enterpriseEditjob.controls['state'].value) {
        this.state_Id = element.Id;
      }
    });

    return new Promise((resolve, reject) => {
      this.enterpriseApis.LoadAllCitiesApi( this.state_Id).subscribe(res => {

        this.ListOfAllCities = res;
        this.allCityList=res;
        if (this.ListOfAllCities.success) {
          resolve(this.ListOfAllCities);
          this.isCitiesSuccess = true;
        } else {
          reject("Error!");
        }
      });

    })

  }
  SearchAllCities(val) {

    if (val.term != '' && val.term.length >= 3) {
      let countryId = '';
      this.ListOfAllCountries.data.forEach(element => {
        if (element.name == this.enterpriseEditjob.controls['jobCountry'].value) {
          countryId = element.Id;
        }
      });
      this.enterpriseApis.SearchAllCitiesApi(countryId, val.term).subscribe(res => {
        this.SearchListOfAllCities = res;
        if (this.SearchListOfAllCities.success) {
          this.setLocationTag(this.SearchListOfAllCities.data);
          this.isSearchCitiesSuccess = true;
        }
      });
    }
  }


  // SearchAllCities(val) {

  //   if (val.term != '') {
  //     let countryId = this.enterpriseEditjob.controls['country'].value;
  //     this.enterpriseApis.SearchAllCitiesApi(countryId, val.term).subscribe(res => {

  //       this.SearchListOfAllCities = res;
  //       if (this.SearchListOfAllCities.success) {
  //         this.setLocationTag(this.SearchListOfAllCities.data);
  //         this.isSearchCitiesSuccess = true;
  //       }
  //     });
  //   }
  // }

  setFilter() {
    // this.addjob();
  }
  tabActive(tab) {
    if (tab === '1') {
      this.tabAll = true;
      this.enterpriseEditjob.controls['favourite_status'].value('All');
      this.setFilter();

    } else {
      this.tabAll = false;
      this.enterpriseEditjob.controls['favourite_status'].value('All');
      this.setFilter();
    }
  }

  setLocationTag(data: any) {
    this.companies = [];
    data.forEach((c, i) => {
      this.companies.push(c.name);
    });
  }



  updatejob() {
    this.Ajsubmitted = true;
    let keywordsdata = this.enterpriseEditjob.controls['keywords'].value;
    let location = this.enterpriseEditjob.controls['jobLocation'].value;
    /*let data: string = '';
    if (keywordsdata.length > 0) {
      data = keywordsdata[0].value;
      for (var i = 1; i < keywordsdata.length; i++) {
        data = data + "," + keywordsdata[i].value;
      }
    }
    let location_data: string = "";
    if (location.length > 0) {
      location_data = location[0];
      for (var i = 1; i < location.length; i++) {
        location_data = location_data + "," + location[i];
      }
    }
    this.enterpriseEditjob.controls['jobLocation'].setValue(location_data);
    this.enterpriseEditjob.controls['keywords'].setValue(data);*/
    this.enterpriseApis
      .enterpriseUpdateJob(this.enterpriseEditjob.value, this.jobId)
      .subscribe(res => {
        // this.listShow = true;
        this.updateresponse = res;
        if (this.updateresponse.success) {
          this.toastr.successToastr(this.updateresponse.message, "", {
            position: "top-right"
          });
          // this.profileLoader = false;
          this.enterpriseEditjob.reset();
          this.router.navigateByUrl("/ep/enterprise/myjoblist");
        } else {
          this.toastr.errorToastr(this.updateresponse.message, "", {
            position: "top-right"
          });
        }
      });
  }

  pipe = new DatePipe('en-US');
  addjob() {

    // if (this.userData.data.credentials.userType === 'AD') {
    //   let userid=this.userData.data.credentials.userId
    //   this.enterpriseEditjob.controls['createdBy'].setValue(userid);
    // }
    // if (this.userData.data.credentials.userType === 'SA') {


    //   this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
    //   this.enterpriseEditjob.controls['createdBy'].setValue(this.createdby);

    // }
    // if (this.userData.data.credentials.userType === 'EP') {
    //   this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
    //   this.enterpriseEditjob.controls['createdBy'].setValue(this.createdby);

    //   var ID=this.userData.data.credentials.userId
    //   this.client_id=ID.substring(6)
    //   this.enterpriseEditjob.controls['registerBy'].setValue(this.userData.data.credentials.userId);
    //   this.enterpriseEditjob.controls['updatedBy'].setValue(this.userData.data.credentials.userId);

    // }

    // if (this.userData.data.credentials.userType == 'EP' || this.userData.data.credentials.userType == 'SA') {
    //   this.createdby = JSON.parse((localStorage.getItem('CreatedBy')));
    //   this.enterpriseEditjob.controls['createdBy'].setValue(this.createdby);
    // }
    // else {
    //   let userid=this.userData.data.credentials.userId
    //   this.enterpriseEditjob.controls['createdby'].setValue(userid);

    // }
//this.createdby = (localStorage.getItem('CreatedBy'));

    var street1 = this.enterpriseEditjob.controls['street1'].value;
    this.enterpriseEditjob.controls['street1'].setValue(street1.trim());

    // var jobDescription=this.enterpriseEditjob.controls['jobDescription'].value;
    // var description=this.enterpriseEditjob.controls['jobDescription'].setValue(jobDescription.trim());

    var jobDescription=this.enterpriseEditjob.controls['jobDescription'].value;
    this.enterpriseEditjob.controls['jobDescription'].setValue(jobDescription.trim());
    var description=this.enterpriseEditjob.controls['jobDescription'].value;
  //  this.enterpriseEditjob.controls['description'].setValue(description.trim());

    if(this.checkdescription==true){
   this.enterpriseEditjob.controls['description'].setValue(description);
    }else{
      this.enterpriseEditjob.controls['description'].setValue(this.JobData.data.description);
    }
    this.Ajsubmitted = true;
    if (this.enterpriseEditjob.valid) {

      const skillsList = this.enterpriseEditjob.controls['skills'].value;
      const jobLocationList = this.enterpriseEditjob.controls['jobLocation'].value;
      let keyarr = '';
      let keyarr1 = '';

      try {
        let count = 0;
        if (skillsList.length > 1) {
          skillsList.forEach((element: any) => {
            if (count === 0) {
              keyarr = (element.value === undefined ? element : element.value);
            } else {
              keyarr = keyarr + ',' + (element.value === undefined ? element : element.value);
            }
            count++;
          });

        } else {
          keyarr = (skillsList[0].value === undefined ? skillsList : skillsList[0].value);
        }
      } catch (error) {
        keyarr = '';
      }
      try {
        let count1 = 0;
        if (jobLocationList.length > 1) {
          jobLocationList.forEach((element: any) => {
            if (count1 === 0) {
              keyarr1 = (element.value === undefined ? element : element.value);
            } else {
              keyarr1 = keyarr1 + ',' + (element.value === undefined ? element : element.value);
            }
            count1++;
          });

        } else {
          keyarr1 = (jobLocationList[0].value === undefined ? jobLocationList : jobLocationList[0].value);
        }
      } catch (error) {
        keyarr1 = '';
      }

      this.enterpriseEditjob.controls['jobLocation'].setValue(keyarr1);
      this.enterpriseEditjob.controls['keywords'].setValue(keyarr);
      this.enterpriseEditjob.controls['joiningDate'].setValue(this.shareservice.convertDateIntoUtc(this.enterpriseEditjob.controls['joiningDate'].value));
      if (this.enterpriseEditjob.controls['joiningDate'].value == "") {
         this.enterpriseEditjob.controls['joiningDate'].markAsTouched();
      }

      this.enterpriseEditjob.controls['joiningDate'].setErrors(null);
      let totalOpenings = this.enterpriseEditjob.controls['totalOpenings'].value;
      let annualPayMax = this.enterpriseEditjob.controls['annualPayMax'].value;
      let jobMonths = this.enterpriseEditjob.controls['jobDuration'].value;

      this.jobAmount = totalOpenings * annualPayMax * jobMonths * 160

      let preAmount = this.totalalljobBudget - this.pretotalAmount

      var preAllJobAmount = +this.jobAmount;
      let alltotaljobAmount = preAllJobAmount + preAmount;
      if (this.userData.data.credentials.userType === 'AD') {
        if (alltotaljobAmount <= this.totalexceed) {
          this.enterpriseEditjob.controls['clientId'].setValue(this.clientId);
          this.enterpriseEditjob.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
          this.enterpriseApis.enterpriseUpdateJob(this.enterpriseEditjob.value, this.jobId).subscribe((res: any) => {
            if (res.success) {
              if (this.userData.data.credentials.userType === 'AD') {
                this.toastr.successToastr(res.message, 'Done!');
                // this.router.navigateByUrl("/ad/admin/projects");
                this.router.navigate(['/ad/admin/project/',this.clientId, this.projectId]);
              }
              else if (this.userData.data.credentials.userType === 'EP') {
                this.toastr.successToastr(res.message, 'Done!');
                this.router.navigateByUrl("ep/enterprise/myjoblist");
              }

            } else {
              this.toastr.errorToastr(res.message, 'Error!');
              return false;
            }
          });
        } else {
          this.toastr.errorToastr('total job amount less then exceed amount of job', '', { position: 'top-right' });
        }
      }
      if (this.userData.data.credentials.userType === 'EP') {
        this.enterpriseEditjob.controls['createdDate'].setValue(this.shareservice.convertDateIntoUtc(new Date()));
        this.enterpriseApis.enterpriseUpdateJob(this.enterpriseEditjob.value, this.jobId).subscribe(res => {
          // this.listShow = true;
          this.updateresponse = res;
          if (this.updateresponse.success) {
            this.toastr.successToastr(this.updateresponse.message, "", {
              position: "top-right"
            });
            // this.profileLoader = false;
            this.enterpriseEditjob.reset();
            this.router.navigateByUrl("/ep/enterprise/myjoblist");
            if (this.page == 1) {
              this.router.navigateByUrl("/ep/enterprise/projects");
            }

            else {
              this.router.navigateByUrl("/ep/enterprise/myjoblist");
            }
          } else {
            this.toastr.errorToastr(this.updateresponse.message, "", {
              position: "top-right"
            });
          }
        });
      }

    }
    else {
      let msg = `Please Fill ${this.findInvalidControls()}.`;
      let message = msg.split(' ');
      if(message[2] == "joiningDate."){
        this.toastr.warningToastr('Please Select Start Date', 'Warning!');
      }
      else{
        this.toastr.warningToastr(msg, 'Warning!');
      }
      return false;
    }


  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.enterpriseEditjob.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
        break;
      }
    }
    return invalid;
  }





  minPayValidation(e) {
    if (e.target.value !== '') {
      if (e.target.value == "0" || e.target.value == "00" || e.target.value == "000" ||
        e.target.value == "0000" || e.target.value == "00000" || e.target.value == "000000" ||
        e.target.value == "0000000" || e.target.value == "00000000" || e.target.value == "000000000" || e.target.value == "0000000000") {
        this.minError = true;
        this.enterpriseEditjob.controls['annualPayMin'].setErrors({ 'invalid': true });
      } else {
        this.minError = false;
      }
    } else {
      this.minError = false;
    }
  }


  maxPayValidation(e) {
    if (e.target.value !== '') {
      if (e.target.value == "0" || e.target.value == "00" || e.target.value == "000" ||
        e.target.value == "0000" || e.target.value == "00000" || e.target.value == "000000" ||
        e.target.value == "0000000" || e.target.value == "00000000" || e.target.value == "000000000" || e.target.value == "0000000000") {
        this.maxError = true;
      } else {
        this.maxError = false;
      }
    } else {
      this.maxError = false;
    }
  }


  compareExpLevel(e) {

    let min = parseInt(this.enterpriseEditjob.controls['requiredExpLevelMin'].value);
    let max = parseInt(this.enterpriseEditjob.controls['requiredExpLevelMax'].value);
    if (max != NaN) {
      if (min > max) {
        this.comparevalError = true;
        this.enterpriseEditjob.controls['requiredExpLevelMax'].setErrors({ 'invalid': true });
      }
      else {
        this.comparevalError = false;
      }
    }
    else {
      this.comparevalError = false;
    }
  }


  compareAnnualPay(e) {
    let min = parseInt(this.enterpriseEditjob.controls['annualPayMin'].value);
    let max = parseInt(this.enterpriseEditjob.controls['annualPayMax'].value);
    if (max != NaN) {
      if (min > max) {
        this.payComparevalError = true;
        this.enterpriseEditjob.controls['annualPayMax'].setErrors({ 'invalid': true });
      }
      else {
        this.payComparevalError = false;
      }
    }
    else {
      this.payComparevalError = false;
    }
  }


  removeZero(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 48 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }


  omit_special_char(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57));
  }

  omit_special_char_number(event: { charCode: any; }) {
    const k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k <= 48 && k >= 57));
  }

  showOverRate = false;
  // onChange_OverTimeAllow(id:number){
  //   if(id == 1){
  //     this.showOverRate = true;
  //   }
  //   if(id == 2){
  //     this.showOverRate = false;
  //   }
  // }

  isShow = false;
  onChange_OverTimeAllow(selectedValue: number) {
    //console.log(selectedValue);
    if (selectedValue == 1) {
      this.enterpriseEditjob.controls['overTimePayRate'].setValidators(Validators.required);;
      this.enterpriseEditjob.controls['overTimeMax'].setValidators(Validators.required);
      this.isShow = true;
    }
    if (selectedValue == 2) {
      this.enterpriseEditjob.controls['overTimePayRate'].clearValidators();
      this.enterpriseEditjob.controls['overTimePayRate'].reset();
      this.enterpriseEditjob.controls['overTimeMax'].clearValidators();
      this.enterpriseEditjob.controls['overTimeMax'].reset();
      this.isShow = false;
    }

  }

  subadminList() {
    this.enterpriseApi.subenterprises().subscribe(res => {
      this.subList = res;
      //console.log('data', res)
      if (this.subList.success) {
        this.asynlist = true;
      } else {
      }
    });
  }
  searchStateValue = false;
searchState(countryId, e) {
  if (e.target.value != '') {
    this.enterpriseApi.searchState(countryId, e.target.value).subscribe((res: any) => {

      if (res.data.length == 0) {
        this.searchStateValue = true;
      }
      if (res.success) {
        this.ListOfAllStates = res;
        if (res.data.length != 0) {
          this.searchStateValue = false;
        }
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }
  else {

    this.ListOfAllStates = this.allStateList;
    this.searchStateValue = false;
  }

}

overtimeError=false;
  checkOvertimeValue(){
    let min = parseInt(this.enterpriseEditjob.controls['overTimePayRate'].value);
    let max = parseInt(this.enterpriseEditjob.controls['overTimeMax'].value);
    if (max != NaN) {
      if (min > max) {
        this.overtimeError = true;
        this.enterpriseEditjob.controls['overTimeMax'].setErrors({ 'invalid': true });
      }
      else {
        this.overtimeError = false;
      }
    }
    else {
      this.overtimeError = false;
    }
  }

searchCityValue = false;
searchCity(stateId, e) {
  if (e.target.value != '') {
    this.enterpriseApi.searchCity(stateId, e.target.value).subscribe((res: any) => {

      if (res.data.length == 0) {
        this.searchCityValue = true;
      }
      if (res.success) {
        this.ListOfAllCities = res;
        if (res.data.length != 0) {
          this.searchCityValue = false;
        }
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }
  else {
    this.ListOfAllCities = this.allCityList;
    this.searchCityValue = false;
  }

}
//Open popup for update description
DescriptionPopup(){
  this.isApprovalPopUp = !this.isApprovalPopUp;
}

//This function use for update description for admin

sTbState: string = 'invisible';
UpdateDescription(e, el){
  this.sTbState = (this.sTbState === 'invisible' ? 'visible' : 'invisible');
  if (this.sTbState === 'visible') {
    el.focus();
  }

  this.isApprovalPopUp=false;
  // this.enterpriseEditjob.nativeElement.focus();
  var description=this.JobData.data.description
  var checkdes=this.JobData.data.checkdescription
  if(checkdes != true ||checkdes != 1){
      this.checkdescription=true;
  }
}

// 20200929: Akhilesh, Special charcter validation, jira no 70
omit_validate_specialchar(event: { charCode: any; }) {
  const k = event.charCode;
   if(k==33 || k==36 || k==37 ||k==44 || k== 58 || k== 60 || k== 61  || k== 62  || k== 63  || k== 91  || k== 92  || k== 93  || k== 94  || k== 96  || k== 123 || k== 124  ||  k ==125 ||  k == 126  ){
     this.toastr.errorToastr('Special characters allowed  # * - _ ( ) /  “ + & @ ; .','Validation!');
     return false}
   }
}
