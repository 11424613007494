
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { Time } from '@angular/common';
import { DatePipe } from '@angular/common';
import { EnterpriseService } from '../enterprise.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { PreviewResumePopupComponent } from '../preview-resume-popup/preview-resume-popup.component';
import { Title } from '@angular/platform-browser';
import {AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';



const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};


declare let Twilio: any; //declare moment

export interface DialogData {
  jobId: string;
  date: Date;
  time: Time;
}

var room: any;
var enableVideo = true; //default. Set to false for broadcasting apps
var enableAudio = true; //default. Set to false for avoiding echoes in demos
var numVideoTracks = 0;
var userName;

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css']
})
export class InterviewComponent implements OnInit {
  activeDayIsOpen: boolean = true;
  videoStart: Boolean = false;
  selectedMoment: Date = new Date();
  listShow: boolean = false;
  filterOption: FormGroup;
  asstDataForm: FormGroup;
  twilioRoom: any;
  interviewData: any;
  counter: string = '';
  roomName: string = '';
  preLoader: boolean = false;
  videoToogle: boolean = false;
  interviewStatus: String = 'select';
  isInviter: boolean = false;


  @ViewChild('modalContent',{static:false}) modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [
    // {
    //   start: startOfDay(new Date()),
    //   end: endOfDay(new Date()),
    //   title: 'An event with no end date',
    //   color: colors.yellow,
    // }
  ];
  viewDate: Date = new Date();
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];


  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  userData: any;
  // identity: string = '';
  constructor(
    private epAPI: EnterpriseService,
    private _formBuilder: FormBuilder,
    private toastr: ToastrManager, private AuthenticationService: AuthenticationService,
    private dialog: MatDialog,
    private _router: Router, private datePipe: DatePipe, private modal: NgbModal,private _titleService:Title, private shareservice:ShareService) {


		this.AuthenticationService.jwtTokenExpCheck();

    // for title set
    var newTitle = 'Virtusa | Interview';
    this._titleService.setTitle( newTitle );

    this.userData = JSON.parse(localStorage.getItem('udata'));
    this.filterOption = this._formBuilder.group({
      PageSize: 10,
      PageNumber: 1,
      interview_type: 'Upcoming',
      scheduleDate: ''
    });

    this.asstDataForm = this._formBuilder.group({
      date: [''],
      candidate: [''],
      jobTitle: [''],
      skill: [''],
      rating: [''],
      jobId: [''],
      jobseekerId: [''],
      comments: [''],
      remarks: [''],
      member_name: [''],
      designation: ['']
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.epAPI.inviteUserList().subscribe((res: any) => {
      if (res.data.length > 0) {
        res.data.forEach(items => {
          this.dropdownList.push({ item_id: items.userId, item_text: items.userName });
        });
      }
    });





  }
  ngOnInit() {
    this.InterListData();

    // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2RiNDM2NWE2YzY0NmE5MGRhNjEzNzM3N2EyOTFkNjZhLTE1NTM4Njc2MTYiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJUeHM5QjZRTGdIZXg0bEhjTFg1RyIsInZpZGVvIjp7InJvb20iOiJGY29LNEdxcEprIn19LCJpYXQiOjE1NTM4Njc2MTYsImV4cCI6MTU1Mzg3MTIxNiwiaXNzIjoiU0tkYjQzNjVhNmM2NDZhOTBkYTYxMzczNzdhMjkxZDY2YSIsInN1YiI6IkFDMGNlODgwZDI0YjMyMmRmNWFlOTk5M2U3NDU1MTgwNDkifQ.D0JFJVeBHpFG1TNOyW6WvMPISkUNpDVn-Uq6TiU8hJo";

  }
  InterFilter() {
    this.filterOption.controls['scheduleDate'].setValue('');
    this.InterListData();
  }

  GetTime() {
  }
  // enterRoom
  accessTokenGl = ''
  startInterview() {
		this.AuthenticationService.jwtTokenExpCheck();

    this.videoToogle = true;
    let chat = {
      srNo: this.interviewData.currentinterview[0].srNo,
      jobId: this.interviewData.currentinterview[0].jobId,
      userName: this.interviewData.currentinterview[0].fullName,
      jobseekerId: this.interviewData.currentinterview[0].jobseekerId
    };
    this.epAPI.createChatRoom(chat).subscribe((res: any) => {
      this.videoToogle = false;
      if (res.success) {
        this.roomName = res.data.roomName;
        this.accessTokenGl = res.data.accessToken;
        this.getInterview(res.data.accessToken);
        // this.toastr.successToastr(res.message, 'Success!')
      } else {
        this.toastr.errorToastr(res.message, 'Error!')
      }
    });
  }

  InterListData() {
		this.AuthenticationService.jwtTokenExpCheck();
    this.preLoader = true;
    this.epAPI.interviewList(this.filterOption.value).subscribe((res: any) => {
      this.listShow = true;

      this.preLoader = false;

      if (res.success) {
        this.interviewData = res.data;
        this.events = [];
        this.interviewData.calendardata.forEach(items => {
          this.events.push({
            start: startOfDay(new Date(items.scheduleDate)),
            end: endOfDay(new Date(items.scheduleDate)),
            title: ``,
            color: colors.yellow,
          })
        })


        // timer js   start
        if (this.interviewData.length > 0) {
          setInterval(() => {
            let distance =
              new Date(
                this.interviewData.currentinterview[0].scheduleDate
              ).getTime() - new Date().getTime();
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            this.counter = days + 'day ' + hours + 'hrs ' + minutes + 'min ';
            // .getTime())
          }, 1000);
        }

      } else {
        this.toastr.errorToastr(res.message, 'Error!');
        this._router.navigate(['/home']);
      }
    });
  }


  getInterview(token) {
		this.AuthenticationService.jwtTokenExpCheck();

    let identity = '';
    const Video = Twilio.Video;
    Video.connect(token, { name: this.roomName })
      .then(room => {
        this.twilioRoom = room;
        this.toastr.successToastr("Video call start....", 'Success!')
        this.videoStart = true;
        this.setAssessment();
        //Display local tracks, if any
        room.localParticipant.tracks.forEach(function (track) {
          if (track.kind == 'audio') return; //Typically, I don't want to listen my own audio
          var mediaElement = track.attach();
          var usertype = room.localParticipant.identity.split("-")[0];
          if (usertype == 'Admin' || usertype == 'Inviter') {
            document
              .getElementById('divRemoteVideoContainer')
              .appendChild(mediaElement);
          } else {
            document
              .getElementById('divLocalVideoContainer')
              .appendChild(mediaElement);
          }
        });


        //Display currently connected participants' tracks, if any
        room.participants.forEach(function (participant) {
          participant.tracks.forEach(attachTrack);
          manageConnectedParticipant(participant);
        });

        //Add handlers for managing participants events
        room.once('participantConnected', manageConnectedParticipant);
        room.on('participantConnected', manageConnectedParticipant);
        room.on('participantDisconnected', manageDisconnectedParticipant);
        room.once('participantDisconnected', manageDisconnectedParticipant);
        room.on('disconnected', manageDisconnected);

      })
      .catch(error => {
        if (error == 'TwilioError: Access Token expired or expiration date invalid') {
          this.toastr.warningToastr('Interview completed', "Warning : ")
        } else {
          this.toastr.errorToastr(error, "Error - 404!")
        }
      });

    function manageConnectedParticipant(participant) {
      identity = participant.identity;
      participant.on('trackAdded', attachTrack);
      participant.on('trackRemoved', detachTrack);
    }

    function manageDisconnectedParticipant(participant) {
      participant.tracks.forEach(detachTrack);
    }

    function manageDisconnected(room, error) {
      if (error) {
      } else {
        room.disconnected()
      }

      room.participants.forEach(function (participant) {
        manageDisconnectedParticipant(participant);
      });

      room.localParticipant.tracks.forEach(function (track) {
        track.stop();
        detachTrack(track);
      });
    }

    function attachTrack(track) {
      var mediaElement = track.attach();
      var usertype = identity.split("-")[0];
      if (usertype == 'Admin' || usertype == 'Inviter') {
        document
          .getElementById('divRemoteVideoContainer')
          .appendChild(mediaElement);
      } else {
        document
          .getElementById('divLocalVideoContainer')
          .appendChild(mediaElement);
      }
      if (track.kind == 'video') updateDisplay(1);
    }

    function detachTrack(track) {
      track.detach().forEach(function (el) {
        el.remove();
      });
      if (track.kind == 'video') updateDisplay(-1);
    }

    function updateDisplay(num) {
      numVideoTracks += num;
      var videoTagWidth = 100 / (1 + numVideoTracks);

      var remoteVideoTags = document.querySelectorAll(
        '#divRemoteVideoContainer video'
      );
      // remoteVideoTags.forEach(function (videoTag) {
      //   // videoTag.style.width = +videoTagWidth + '%';
      // });
    }

    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

    let calendarDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
    this.filterOption.controls['scheduleDate'].setValue(calendarDate);
    this.InterListData();

  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }
  inviteParticipent() {
		this.AuthenticationService.jwtTokenExpCheck();

    let user = {
      srNo: this.interviewData.currentinterview[0].srNo,
      jobId: this.interviewData.currentinterview[0].jobId,
      userId: this.selectedItems[0].item_id,
      userName: this.selectedItems[0].item_text,
      roomName: this.roomName,
      createdDate:this.shareservice.convertDateIntoUtc(new Date())
    }

    this.epAPI.generateinvite(user).subscribe((res: any) => {
      if (res.success) {
        this.toastr.successToastr(res.message, 'Success!')
      } else this.toastr.errorToastr(res.message, 'Error!');
    });

  }

  joingroup() {
		this.AuthenticationService.jwtTokenExpCheck();

    let body = {
      srNo: this.interviewData.currentinterview[0].srNo,
      jobId: this.interviewData.currentinterview[0].jobId,
    }
    this.epAPI.joinGroupApi(body).subscribe((res: any) => {
      if (res.success) {
        this.toastr.successToastr(res.message, 'Success!');
        this.isInviter = true;
        this.roomName = res.data.roomName;
        this.getInterview(res.data.accessToken);

      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    })
  }
  setAssessment() {
    this.asstDataForm.controls['date'].setValue(this.interviewData.currentinterview[0].Date);
    this.asstDataForm.controls['candidate'].setValue(this.interviewData.currentinterview[0].fullName);
  }
  asst_submit() {
    if (this.asstDataForm.valid) {
    } else {
      return false;
    }

  }

  setinterview() {
		this.AuthenticationService.jwtTokenExpCheck();


    const body = {
      actionType: this.interviewStatus,
      SrNo: this.interviewData.currentinterview[0].srNo,
      jobseekerId: this.interviewData.currentinterview[0].jobseekerId,
    };
    this.epAPI.getInterviewStatus(body).subscribe((res: any) => {
      if (res.success) {
        this.toastr.successToastr(res.message, 'Success!');
        // this.disconnect();
      } else {
        this.toastr.errorToastr(res.message, 'Error!');
        return false;
      }
    })
  }
  disconnect() {
		this.AuthenticationService.jwtTokenExpCheck();

    try {
      if (this.interviewStatus != 'select') {
        let body = {
          srNo: this.interviewData.currentinterview[0].srNo,
          jobId: this.interviewData.currentinterview[0].jobId,
          roomName: this.roomName
        }
        this.epAPI.InterviewExit(body).subscribe((res: any) => {
          if (res.success) {
            this.toastr.successToastr(res.message, 'Done!');
            this.videoStart = false;
            this.InterListData();
            this.twilioRoom.disconnect();
          }
        })

      } else {
        this.toastr.warningToastr('Please select Interview Staus.', 'Warning!');
        return false;
      }

    } catch (error) {
    }

  }


  videoSynch: boolean = false;
  audioSynch: boolean = false;

  muteFn(val) {
    if(val === 'audio'){
      this.twilioRoom.localParticipant.audioTracks.forEach(audioTrack => {
       if(!this.audioSynch){
        audioTrack.disable();
       }else{

         audioTrack.enable();
       }

      });
      this.audioSynch = !this.audioSynch;
    }

    if(val === 'video'){
    this.twilioRoom.localParticipant.tracks.forEach(track => {
      if (track.isEnabled) {
        this.videoSynch = false;
        // track.mediaStreamTrack.muted = false;
        track.disable();
      } else {
        this.videoSynch = true;
        // track.mediaStreamTrack.muted = true;
        track.enable();
      }
    });
      this.videoSynch = ! this.videoSynch;
  }
  }


  isLogin='true';
  previewDialog(details): void {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){

    const dialogRefDetail = this.dialog.open(PreviewResumePopupComponent, {
      width: 'auto',
      data: details
    });

    dialogRefDetail.afterClosed().subscribe(result => {
      // this.animal = result;
    });
  }
  }


}
