import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ShareService } from '../share.service';
import { EventEmitter } from 'events';
import { ToastrManager } from 'ng6-toastr-notifications';
import { JobDetailPopupComponent } from '../../enterprise/job-detail-popup/job-detail-popup.component';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/app.service';
@Component({
  selector: 'app-browse-job',
  templateUrl: './browse-job.component.html',
  styleUrls: ['./browse-job.component.css']
})
export class BrowseJobComponent implements OnInit {
  @ViewChild(MatPaginator,{static:false}) paginators: MatPaginator;
  selected : any;
  paginator = {
    pageSize: 20,
    length: 0,
    pageSizeOptions: [5, 10, 20, 100],
    pageIndex: 0
  };

  @Input() logintype = '';
  isVisibleFilterBtn = true;
  dataType = '';
  joblistData: any;
  islstopen = '';
  istabope = 'date';
  jobFilter: FormGroup;
  filterList: any;
  ddlFilterList: any;
  panelOpenState = false;
  listView = false;
  preLoader = false;
  readmore: any = {};
  img_path = '/assets/images/user.svg';
  serverpth = '';
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];

  constructor(
    private _formBuilder: FormBuilder,
    private shareServices: ShareService,
    private toastr: ToastrManager,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private _titleService: Title,
    // private JSApi: JobseekerService
    private appAPI: AuthenticationService,
    private route:Router
  ) {
    this.serverpth = `${this.appAPI.url}`;
    const newTitle = 'Virtusa | Client Jobs';
    this._titleService.setTitle( newTitle );
    this.readmore = {};
    this.filterInit();
    this.preLoader = true;
    this.shareServices.getfiltersBrowsejobs().subscribe((res: any) => {
      this.preLoader = false;
      if (res.success) {
        this.filterList = res.data;
        if(res.data.length == 0){
          this.isVisibleFilterBtn = false;
        }

      } else {
        this.toastr.errorToastr(res.message, 'Error!');
      }
    });
  }

  OpenlfMenu() {
    if (this.islstopen === '') {
      this.islstopen = 'open_mob_tab';
    } else {
      this.islstopen = '';
    }
  }
  ngOnInit() {
    this.dataType = this.logintype;
    this.JoblistDatafc();
    this.islstopen = 'open_mob_tab';
  }

  filterInit() {
    this.jobFilter = this._formBuilder.group({
      'PageSize': 20,
      'PageNumber': 1,
      'postedDate': '0',
      'jobType': 'All',
      'country': '',
      'minExperience': 0,
      'maxExperience': 0,
      'minAnnualPay': 0,
      'maxAnnualPay': 0,
      'search': '',
      'location': '',
      'jobLocation': '',
      'jobResponse': '',
      'jobRequirement': 'All',
      'orderBy': '',
      'state': '',
      'zipCode': '',
      'fromdate': '',
      'todate': ''
    });
  }
dateFormat(date) {
  return this.datePipe.transform(date, 'dd/MM/yy');
}

searchButton = false;
  loadData(){
    this.searchButton= true;
    this.JoblistDatafc();
  }
  JoblistDatafc() {

    this.preLoader = true;
    switch (this.dataType) {
      case 'SA': {
        if(this.searchButton == true){
          this.jobFilter.controls['PageNumber'].setValue(1);
        }

        this.jobFilter.controls["fromdate"].setValue(this.datePipe.transform(this.jobFilter.controls["fromdate"].value, "yyyy-MM-dd"))
    this.jobFilter.controls["todate"].setValue(this.datePipe.transform(this.jobFilter.controls["todate"].value, "yyyy-MM-dd 23:59:59"))

    if(this.searchButton == true){
      this.jobFilter.controls['PageNumber'].setValue(1);
    }
    this.jobFilter.value.fromdate=(this.shareServices.convertDateIntoUtc( this.jobFilter.controls["fromdate"].value))
    this.jobFilter.value.todate=(this.shareServices.convertDateIntoUtc( this.jobFilter.controls["todate"].value))



        this.jobFilter.controls["fromdate"].setErrors(null)
        this.jobFilter.controls["todate"].setErrors(null)
        this.shareServices.jobsListAPI(this.jobFilter.value).subscribe(res => {
          this.preLoader = false;
          this.joblistData = res;
          console.log('====',this.joblistData)
          if(this.searchButton == true)
          {
            this.paginators.pageIndex = 0;
          }
          this.searchButton = false;

          this.img_path = `${this.serverpth}/api/enterprise/image/profileimage/`;
          if (this.joblistData.success) {
            this.listView = true;
            if (this.joblistData.data.length > 0) {
              this.paginator.length = this.joblistData.data[0].totalCount;
            } else {
              this.paginator.length = 0;
            }
          } else {

          }
        });
        break;
      }
      case 'EP': {
        break;
      }
      case 'JS': {
        break;
      }
    }
  }

  toggletabOpen(val: string) {
    this.istabope = val;
    this.jobFilter.controls['orderBy'].setValue(val);
    this.searchButton = true;
    this.JoblistDatafc();
  }

  jsonArrayCnvrtVl(val: {}) {
    return Object.keys(val);
  }

  jsonArrayCnvrt(val) {
    return Object.values(val);
  }

  resumePush(data:any){
this.route.navigateByUrl("/st/staff/PusedResume/"+data.jobId+'/'+data.jobTitle)
  }
  countryData(ddlType: any, values: any) {
    this.ddlFilterList = [];
    switch (ddlType) {
      case 'country': {
        if (this.jobFilter.controls['country'].value.length >= 2) {
          this.shareServices.country_staff().subscribe((res: any) => {
            if (res.success) {
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter country'];
            }
          });
        }
        break;
      }
      case 'jobLocation': {
        if (this.jobFilter.controls['jobLocation'].value.length >= 2) {
          this.shareServices.jobLocation_staff(this.jobFilter.controls['jobLocation'].value).subscribe((res: any) => {
            if (res.success) {
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter Job Location'];
            }
          });
        }
        break;
      }
      case 'state': {
        if (this.jobFilter.controls['state'].value.length >= 2) {
          this.shareServices.state_staff(this.jobFilter.controls['state'].value).subscribe((res: any) => {
            if (res.success) {
              this.ddlFilterList = res.data;
            } else {
              this.ddlFilterList = ['Enter State'];
            }
          });
        }
        break;
      }
    }
  }

  clearData() {
    this.ddlFilterList = [];
  }

  filterReset() {

    this.filterInit();
    this.paginator.pageSize=20;
    this.JoblistDatafc();
    this.paginators.pageIndex = 0;
  }

  setpaggination(e) {
    this.searchButton = false;
    // this.jobFilter.controls['PageSize'].setValue(e.pageSize);
    // this.jobFilter.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.paginator.pageSize = e.pageSize;
    this.paginator.pageIndex = e.pageIndex;
    this.jobFilter.controls['PageSize'].setValue(e.pageSize);
    this.jobFilter.controls['PageNumber'].setValue(e.pageIndex + 1);

    this.JoblistDatafc();
  }

  isLogin='true';
  openDialog(id: any): void {
    this.appAPI.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    const dialogRef = this.dialog.open(JobDetailPopupComponent, {
      width: 'auto',
      data: { jobId: id }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  }
  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }
  staffingClearForm() {
    //window.location.reload();
    // this.today = new Date().toISOString().split('T')[0];
    // this.from_Date = null;
    this.jobFilter = this._formBuilder.group({
      'PageSize': 20,
      'PageNumber': 1,
      'postedDate': '0',
      'jobType': 'All',
      'country': '',
      'minExperience': 0,
      'maxExperience': 0,
      'minAnnualPay': 0,
      'maxAnnualPay': 0,
      'search': '',
      'location': '',
      'jobLocation': '',
      'jobResponse': '',
      'jobRequirement': 'All',
      'orderBy': '',
      'state': '',
      'zipCode': '',
      'fromdate': '',
      'todate': ''
    });
    this.paginator.pageSize = 20;
    this.JoblistDatafc();
    this.paginators.pageIndex = 0;
  }
  from_Date:String;
  dateForToDate(type:number){
    if(type == 1){
      this.from_Date = new Date(this.datePipe.transform(this.jobFilter.controls['fromdate'].value)).toISOString();
    }

    if(type == 2){
      this.today = new Date(this.datePipe.transform(this.jobFilter.controls['todate'].value)).toISOString();
    }
  }
}
