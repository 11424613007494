import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validator, Validators, RequiredValidator } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { EnterpriseService } from '../../enterprise/enterprise.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AuthenticationService } from '../../app.service'
import { Title } from '@angular/platform-browser';
import { LayoutService } from 'src/app/share-module/layout.service';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isSearchBar: boolean = false;
  companies: any[] = [];
  loading = false;
  companiesNames = [];

  cities: any[] = [];

  @Output() staffloginEmit = new EventEmitter();

  routerClass: string;
  isLogin: boolean;
  // isLoginF: string = 'false';
  constructor(
    private router: Router,
    private appAPI: AuthenticationService,
    private EPApies: EnterpriseService, private toastr: ToastrManager, private _formBuilder: FormBuilder,private _titleService : Title, private layoutService:LayoutService) {

      //for set title
    var newTitle = 'Virtusa | Home';
    this._titleService.setTitle( newTitle );
    this.isLogin = localStorage.getItem('islogin') == 'true' ? true : false;
    this.HomePage = this._formBuilder.group({
      country: ['', Validators.required],
      skills: [""],
      jobLocation: [""]
    })
  }
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  ListOfAllCountries: any;
  isCountrySuccess: boolean = false;
  HomePage: FormGroup;
  ListOfSkill: any;
  ngOnInit() {
    debugger
    //this.layoutService.setShowSidebar(false);

    this.LoadAllCountries();
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  LoadAllCountries() {

    this.EPApies.LoadAllCountriesApiWithoutToken().subscribe(res => {

      // console.log('ListOfAllCountries >>>>>>>  :', res);
      this.ListOfAllCountries = res;
      if (this.ListOfAllCountries.success) {
        this.isCountrySuccess = true;
      }
    });
  }
  LoadAllSkills(val) {

    if (val.term != '') {
      // console.log(val.term);
      this.EPApies.LoadAllSkillApiWithoutToken(val.term).subscribe(res => {

        // console.log('ListOfAllCountries >>>>>>>  :', res);
        this.ListOfSkill = res;
        if (this.ListOfSkill.success) {
          this.setLocationTag(this.ListOfSkill.data);
          this.isCountrySuccess = true;
        }
      });
    }
  }
  LoadAllCities(val) {

    if (val.term != '') {
      // console.log(val.term);
      let country = this.HomePage.controls['country'].value;
      this.EPApies.LoadAllCitiesApiWithoutToken(country, val.term).subscribe(res => {

        // console.log('ListOfAllCountries >>>>>>>  :', res);
        this.ListOfSkill = res;
        if (this.ListOfSkill.success) {
          this.setLocationTag1(this.ListOfSkill.data);
        }
      });
    }
  }

  setLocationTag1(data: any) {

    this.cities = [];
    data.forEach((c, i) => {
      this.cities.push(c.name);
    });
    // console.log("asdsada ==== === == =>", this.cities);
  }

  StaffingLoginOn() {
    this.staffloginEmit.emit('true')
  }

  onClickSearch() {
    this.isSearchBar = true;
  }
  setLocationTag(data: any) {

    this.companies = [];
    data.forEach((c, i) => {
      this.companies.push(c.keywords);
    });
  }


  ExploreJob() {


    // console.log("this.HomePage.value >>>>>>", this.HomePage.value);
    // this.router.navigate(['/browsejob']);
    let search: string = "";
    let searchlocation: string = "";
    if (this.HomePage.value) {
      search = this.HomePage.value.skills[0];
      for (var i = 1; i < this.HomePage.value.skills.length; i++) {
        search = search + ',' + this.HomePage.value.skills[i];
      }
      searchlocation = this.HomePage.value.jobLocation[0];
      for (var j = 1; j < this.HomePage.value.jobLocation.length; j++) {
        searchlocation = searchlocation + ',' + this.HomePage.value.jobLocation[j];
      }
    }
    this.router.navigate(['/browsejob'], { queryParams: { search: search, searchlocation: searchlocation } });
  }

  // addTag(name) {
  //   return { name: name, tag: true };
  // }

  addTagPromise(name) {
    return new Promise((resolve) => {
      this.loading = true;
      setTimeout(() => {
        resolve({ id: 5, name: name, valid: true });
        this.loading = false;
      }, 1000);
    });
  }
  postJobBtn() {
    this.toastr.warningToastr('For posting a job please signup with Enterise.');
  }



}


