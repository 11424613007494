import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { EnterpriseService } from '../enterprise.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialog } from '@angular/material/dialog';
import {AuthenticationService } from '../../app.service';
import { ShareService } from 'src/app/share-module/share.service';
import { DatePipe } from '@angular/common';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { SupplierGroupDetailsComponent } from '../supplier-group-details/supplier-group-details.component';

@Component({
  selector: 'app-assign-job-to-agency-group',
  templateUrl: './assign-job-to-agency-group.component.html',
  styleUrls: ['./assign-job-to-agency-group.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class AssignJobToAgencyGroupComponent implements OnInit {
   // Datepicker takes `Moment` objects instead of `Date` objects.
   date = new FormControl(moment([2017, 0, 1]));
  GroupIdSelected: any = [];
  staffingAgencyGroup_Id : any;
  listOfAllRequest: any;
  preloader: boolean;
  listView: boolean;
  groupForm: FormGroup;
  loadForm: FormGroup;
  listofagency: any;
  paginator = {
    pageSize: 20,
    length: 0,
    pageIndex: 0,
    pageSizeOptions: [10, 20, 50, 100]
  };
  areAllSelected = false;
  data: any;
  record:any;
  job_id:any;
  today: String = new Date().toISOString().split('T')[0];
  todayDate: String = new Date().toISOString().split('T')[0];

  constructor(public dialog: MatDialog, private _titleService: Title, private formBuilder: FormBuilder,
 private AuthenticationService: AuthenticationService,
 private tostr: ToastrManager, private _router: Router, private enterPriseAPI: EnterpriseService, private shareservice:ShareService ,private datepipe:DatePipe) {
  var newTitle = 'Virtusa | Job Allotment';
  this._titleService.setTitle(newTitle);
  this.AuthenticationService.jwtTokenExpCheck();
  this.data = JSON.parse(localStorage.getItem('CurrentGroupRequest'));
  this.record = JSON.parse(localStorage.getItem('CurrentBeelineRequest'));
    this.groupForm = this.formBuilder.group({
      search: '',
      PageSize: 10,
      PageNumber: 1,
      fromdate: '',
      todate: '',
      status:'All'
    });

    this.loadForm = this.formBuilder.group({
      fromdate: '',
      todate: '',
      search: '',
      totalcandidates: '0-All',
      pageSize: 10,
      PageNumber: 1,
      actiontype: 'Update',
      groupId: ''
    });
  }

  ngOnInit() {
    this.loadgrouplist();
  }
  loadgrouplist() {
    this.AuthenticationService.jwtTokenExpCheck();
    this.groupForm.controls['fromdate'].setValue(this.shareservice.convertDateIntoUtc(this.datepipe.transform(this.groupForm.value.fromdate,'MM/dd/yyyy')));
    this.groupForm.controls['todate'].setValue(this.shareservice.convertDateIntoUtcForSearch(this.datepipe.transform(this.groupForm.value.todate,'MM/dd/yyyy')));

    //console.log('this.data', this.data);
    //console.log('sun loadgrouplist');
    this.preloader = true;
    this.enterPriseAPI.SAgroupDetails(this.groupForm.value).pipe(first()).subscribe(res => {
      if (res.success) {
        this.preloader = false;
        this.listOfAllRequest = res;
        this.listView = true;
        if (this.listOfAllRequest.data.length > 0) {
          this.areAllSelected=false;
          this.paginator.length = this.listOfAllRequest.data[0].totalCount;
        } else {
          this.paginator.length = 0;
        }

      }
    });
  }
  toggleAllSelection() {
    this.areAllSelected = !this.areAllSelected;
    const isAllSelected = this.areAllSelected;
    this.listOfAllRequest.data.forEach((item: { selected: boolean; }) => {
      item.selected = isAllSelected;
    });
  }
  checkedUnChecked(candidate: { staffingAgencyGroup_Id: any; }, event: { checked: any; }) {
    let notChecked = 0;
    this.listOfAllRequest.data.forEach((item: { staffingAgencyGroup_Id: any; selected: any; }) => {
      if (item.staffingAgencyGroup_Id === candidate.staffingAgencyGroup_Id) {
        item.selected = event.checked;
      }
      if (!item.selected) {
        notChecked++;
      }
    });
    this.areAllSelected = (notChecked === 0);
  }

  getPaginatorData(e) {
    this.groupForm.controls['pageSize'].setValue(e.pageSize);
    this.groupForm.controls['PageNumber'].setValue(e.pageIndex + 1);
    this.loadgrouplist();
  }
  AssignJobGroup(record: any) {
    this.AuthenticationService.jwtTokenExpCheck()

    let selectedCount1 = 0;
    const recordlenth = record.length;
    record.forEach((count) => {
      if (count.selected === false) {
        selectedCount1 = selectedCount1 + 1;
      }
    });
    if( this.data != null){
    this.job_id = this.data.jobId;
  }else if(this.record != null){
    this.job_id = this.record.sourceId;
  }
    //console.log(selectedCount1);
    if (recordlenth !== selectedCount1) {
      record.forEach((element) => { // apply loop for multiple jobs
        if (element.selected) {
          this.loadForm.controls.groupId.setValue(element.staffingAgencyGroup_Id);
          this.enterPriseAPI.GetSAForAgencyGroup(this.loadForm.value).pipe(first()).subscribe(res => {
            if (res.success) {
              this.listofagency = res.data;
              //console.log('res.data', res.data);
              this.listofagency.forEach((value) => {
                if(value.status == 'Active'){
                  value.staffingAgencyGroup_Id = element.staffingAgencyGroup_Id;
                  value.jobid = this.job_id;
                  value.status = true;
                  //console.log('value', value);
                  this.enterPriseAPI.InsertAsignedJobToSAGroup(value,this.shareservice.convertDateIntoUtc(new Date())).pipe(first()).subscribe(res => {
                  });
                }
              });
            }
          });
        }
        element.selected = false;
      });
      this.tostr.successToastr('Job is assigned to group successfully', "Success!");
    } else {
      this.tostr.errorToastr('Please select at least one supplier group');
      return false;

    }
  }
  selectJob(data, event) {
    if (event.checked) {
      this.GroupIdSelected.push({
        'staffingagencyId': data.staffingAgencyGroup_Id,
      });
    } else {
      for (let i = 0; i < this.GroupIdSelected.length; i++) {
        //console.log(this.GroupIdSelected[i].staffingAgencyGroup_Id);
        if (this.GroupIdSelected[i].staffingAgencyGroup_Id === data.staffingAgencyGroup_Id) {
          this.GroupIdSelected.splice(i, 1);
        }
      }
      //console.log("After Unselected job", this.GroupIdSelected);
    }
  }
  selectPageNo(_e: any) {
    this.loadgrouplist();
  }
  staffingdetlform() {
    this.today = new Date().toISOString().split('T')[0];
    this.from_Date = null;
    this.groupForm.controls['search'].setValue('');
    this.groupForm.controls['todate'].setValue('');
    this.groupForm.controls['fromdate'].setValue('');
    this.loadgrouplist();
  }

  from_Date:String;
dateForToDate(type:number){
  if(type == 1){
    this.from_Date = new Date(this.datepipe.transform(this.groupForm.controls['fromdate'].value)).toISOString();
  }

  if(type == 2){
    this.today = new Date(this.datepipe.transform(this.groupForm.controls['todate'].value)).toISOString();
  }
}

  removeSpaces(e: { currentTarget: { selectionStart: any; }; which: number; preventDefault: () => void; }) {
    const startPos = e.currentTarget.selectionStart;
    if ((e.which === 32 && startPos === 0) || (e.which === 13 && startPos === 0)) {
      e.preventDefault();
    }
  }

  isLogin='true';
  supplierList(record: any) {
    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    localStorage.removeItem('CurrentGroupRequest');
    localStorage.setItem('CurrentGroupRequest', JSON.stringify(record));
    record = {
      update: true
    }
    const dialogRef = this.dialog.open(SupplierGroupDetailsComponent, {
      width: 'auto',
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {

     // //console.log('The dialog was closed');
      //this.loadgrouplist();
    });
  }
  }

  supplierListHistory(record: any) {

    this.AuthenticationService.jwtTokenExpCheck();
    this.isLogin = localStorage.getItem('islogin');
    if(this.isLogin != 'false'){
    localStorage.removeItem('CurrentGroupRequest');
    localStorage.setItem('CurrentGroupRequest', JSON.stringify(record));
    record = {
      update: true,
      isHistory : true
    }
    const dialogRef = this.dialog.open(SupplierGroupDetailsComponent, {
      width: 'auto',
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {

     // //console.log('The dialog was closed');
      //this.loadgrouplist();
    });
  }
  }
}
